import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../apiConfig";
import { useLocation } from "react-router-dom";
import report from "../asserts/report.png";

export default function BugReport() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [reportBug, setReportBug] = useState(false);
  const [personalEmail, setPersonalEmail] = useState("");
  const { register, handleSubmit, reset, setValue } = useForm();
  const [currentLocation, setCurrentLocation] = useState("");
  const [name, setname] = useState("");

  useEffect(() => {
    setCurrentLocation(location.pathname);
    setValue("url", location.pathname); // Update the value in the input field
  }, [location, setValue]);

  const submitBug = async (data) => {
    
    const formData = new FormData();

    formData.append("email", data.email);
    formData.append("url", data.url);
    formData.append("name", data.name);
    formData.append("bug", data.bug);
    formData.append("image", data.image[0]);

    try {
      const response = await axios.post(apiUrl.url.sendusermessage, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        alert("Bug reported successfully");
        setReportBug(false);
        reset();
      }
    } catch (err) {
      // // console,log(err.message);
    }
  };

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const resp = await axios.post(apiUrl.url.geturl + token);
        setPersonalEmail(resp.data.url.email);
        // // console,log(resp.data);
        setValue("name", resp.data.name);
        setValue("email", resp.data.url.email); // Update the email field in the form
      } catch (err) {
        // // console,log(err.message);
      }
    };
    fetchEmail();
  }, [token, setValue]);

  useEffect(() => {
    if (!token) {
      reset();
    }
  }, [token]);

  return (
    <div>
      <div
        className="fixed bottom-6 right-6 bg-gray-300 hover:bg-gray-200 cursor-pointer p-2 rounded-full"
        onClick={() => {
          setReportBug(!reportBug);
        }}
      >
        <img src={report} alt="Report Bug" className="size-8" />
      </div>
      {reportBug && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-4 w-96 h-50 rounded-lg relative">
            <button
              className="absolute top-4 right-4"
              onClick={() => setReportBug(!reportBug)}
            >
              X
            </button>
            <h1 className="text-xl font-semibold">Feedback</h1>
            <form onSubmit={handleSubmit(submitBug)}>
              <input
                type="text"
                className={`w-full p-2 border border-gray-300 rounded-lg mt-2 focus:outline-none ${
                  token && " cursor-not-allowed"
                }`}
                placeholder="Enter your email"
                value={token && personalEmail}
                {...register("email")}
              />
              <input
                type="text"
                className={`w-full p-2 border border-gray-300 rounded-lg mt-2 focus:outline-none ${
                  token && " cursor-not-allowed"
                }`}
                placeholder="Enter your name"
                value={token && name}
                {...register("name")}
              />
              <input
                type="text"
                className={`w-full p-2 border border-gray-300 rounded-lg mt-2 focus:outline-none ${
                  token && " cursor-not-allowed"
                }`}
                placeholder="Enter the page URL"
                value={token && currentLocation} // Use value instead of defaultValue
                {...register("url")}
              />
              <textarea
                className="w-full p-2 border h-40 border-gray-300 rounded-lg mt-2"
                placeholder="Give your feedback here"
                {...register("bug")}
              ></textarea>
              <input
                type="file"
                className="w-full p-2 border border-gray-300 rounded-lg mt-2"
                {...register("image")}
              />
              <div className="my-2">
                <button
                  type="submit"
                  className="bg-[#0052ff] text-white text-xs md:text-sm hover:bg-[#014cec] cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
