import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export default function Connectionsdropdown({
  handlepublic,
  publicview,
  manageCompModal,
  title,
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {title === "profile" || title === "sentcomponent" ? (
          <Menu.Button className=" w-fit my-2 duration-300 font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-black border-black  h-fit">
            More
          </Menu.Button>
        ) : (
          <Menu.Button className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
              />
            </svg>
          </Menu.Button>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            title === "profile" ? " left-0" : "right-0"
          }  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {/* <Menu.Item>
              <div className="hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm cursor-pointer" onClick={handlepublic}>
              {publicview ? "View as Editor" : "View as Public"}
              </div>
            </Menu.Item> */}
            <Menu.Item>
              <div
                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-fit"
                onClick={manageCompModal}
              >
                {title != "sentcomponent"
                  ? "  Remove Connection"
                  : "  Withdraw Request"}
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
