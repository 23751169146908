import { Fragment, useEffect, useRef } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import logo from "../asserts/logo.png";
import "../styles/carousel.css";
import { toast } from "react-toastify";
import { FaSun, FaMoon } from "react-icons/fa";
import { BsBoxArrowUpRight } from "react-icons/bs";

import whitelogo from "../asserts/whitelogopng.png";
import dummyDP from "../asserts/dummyDp.png";
import axios from "axios";
import apiUrl from "../apiConfig";
import { PiDotsNineBold } from "react-icons/pi";
import { Badge } from "keep-react";
import { MdOutlineGroup } from "react-icons/md";
import favilogo from "../asserts/favicon.png";
import { WebSocketContext } from "../contexts/WebSocketContext";
import { logincontext } from "../contexts/Logincontext";
import PublicInterestModal from "./PublicInterestModal";

const navigation = [
  { name: "Sign up", to: "/signup", current: true },
  { name: "Log in", to: "/login", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({
  isDarkMode,
  setIsDarkMode,
  toggleDarkMode,
  url,
  api,
  seturl,
  setapi,
}) {
  const [purl, setpurl] = useState("");
  const [profile, setprofile] = useState(false);
  const [loginbuttons, setloginbuttons] = useState(false);
  const [signoutbuttons, setsignoutbuttons] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isServicesDropDown, setIsServicesDropDown] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [openJobPublicInterestModal, setOpenJobPublicInterestModal] =
    useState(false);
  const [feature, setFeature] = useState("");
  const { socket, disconnectSocket, numberOfNotifications, setSelectedUser } =
    useContext(WebSocketContext);
  const [
    currentuser,
    loginerror,
    UserloginStatus,
    Loginuser,
    Signupuser,
    VerifyOTP,
    VerifyForgotPasswordOTP,
    Signupadmin,
    Logoutuser,
  ] = useContext(logincontext);
  const services = [
    { id: 1, message: "Learning" },
    // { id: 2, message: "Recruiting" },
    { id: 3, message: "Jobs" },
  ];

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsServicesDropDown(false);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropDown(!isServicesDropDown);
    setIsDropdownOpen(false);
  };

  const toggleProfileDropDown = () => {
    setIsServicesDropDown(false);
    setIsDropdownOpen(false);
  };

  const fetchurl = async () => {
    if (token) {
      try {
        const response = await axios.post(apiUrl.url.geturl + token);
        if (response && response.status === 200) {
          seturl(response.data.url.url);
          // // // console,log(response.data);
          navigate("/" + response.data.url.url);
          setapi(!api);
        }
      } catch (err) {
        // // // console,log(err.message);
      }
    }
  };

  const handleSignout = () => {
    disconnectSocket();
    setpurl("");
    setimg(dummyDP);
    Logoutuser();
    setpurl("");
    setimg(dummyDP);
    // localStorage.clear();
    // navigate("/");
    // toast.success("You have succesfully logged out");
  };
  const location = useLocation();
  const [img, setimg] = useState(dummyDP);
  // Create a new Axios instance
  const axiosInstance = axios.create({
    withCredentials: true, // Enable sending cookies with requests
  });

  const [defemail, setdefemail] = useState("");

  useEffect(() => {
    const refreshtoken = localStorage.getItem("refreshtoken");
    const getimg = async () => {
      try {
        // // // // console,log("navbar", reqbody, { token: token });
        if (token) {
          const res = await axios.post(apiUrl.profile.geturlanddp + token);
          // // // console,log(res.data.existing_profile.Profilepic);
          if (res.status === 200) {
            setimg(res.data.profilepic);
            setpurl(res.data.url);
            setdefemail(res.data.email);
          } else if (res.status === 404) {
            Logoutuser();
          }
        }
      } catch (err) {
        // localStorage.removeItem("token");
        // navigate("/");
        // // // console,log(err.message);
      }
    };
    // if (refreshtoken) {
    // }
    getimg();
  }, [token]);

  useEffect(() => {
    const editpagecheck = location.pathname.startsWith("/signup/");
    if (editpagecheck) {
      setprofile(false);
      setloginbuttons(false);
      setsignoutbuttons(true);
    } else {
      if (token) {
        setprofile(true);
        setloginbuttons(false);
        setsignoutbuttons(false);
      } else {
        setprofile(false);
        setloginbuttons(true);
        setsignoutbuttons(false);
      }
    }

    // Add event listener when the component mounts
    window.addEventListener("click", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setIsServicesDropDown(false);
    }
  };

  const [newreqs, setnewreqs] = useState(0);
  useEffect(() => {
    const reqs = async () => {
      try {
        const resp = await axios.post(apiUrl.connections.getnewreqs + token);
        if (resp && resp.status === 200) {
          // // // console,log(resp);
          // // // console,log("received", resp.data.new_requests);
          setnewreqs(resp.data.new_requests);
        }
      } catch (err) {
        // // console,log(err);
      }
    };

    reqs();
  }, [location]);

  const handleJobPublicInterestModal = (data) => {
    setFeature(data);
    setOpenJobPublicInterestModal(!openJobPublicInterestModal);
  };

  return (
    <div className="">
      <div
        className={` fixed  z-[5] w-full  flex justify-center border-b-[0.5px] border-[#eceff1] ${
          isDarkMode ? "bg-[#2a303c]" : "bg-white"
        }`}
      >
        <div className="max-w-screen-xl  min-w-[300px] w-[100%] ">
          <Disclosure as="nav">
            {({ open }) => (
              <>
                <div className="mx-auto px-4  w-full">
                  <div className="relative flex h-12 items-center justify-between w-full ">
                    <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
                      <div
                        className="flex flex-shrink-0 items-center"
                        onClick={token && fetchurl}
                      >
                        <NavLink to={token ? fetchurl : "/"}>
                          <img
                            className="h-8 w-auto md:inline-block hidden "
                            src={isDarkMode ? whitelogo : logo}
                            alt="Your Company"
                          />
                          <img
                            className="h-8 w-auto md:hidden inline-block "
                            src={isDarkMode ? whitelogo : favilogo}
                            alt="Your Company"
                          />
                        </NavLink>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 h-full flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                      {signoutbuttons && (
                        <div>
                          <button
                            onClick={handleSignout}
                            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                          >
                            Sign out
                          </button>
                        </div>
                      )}
                      {loginbuttons && (
                        <div className="hidden sm:block">
                          {/* <NavLink to="/employers/login">
                            <button className="text-black underline text-sm mx-2 font-[550]">
                              For employers
                            </button>
                          </NavLink> */}
                          <NavLink to="/login">
                            <button className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]">
                              Log in
                            </button>
                          </NavLink>
                          <NavLink to="/signup">
                            <button className=" bg-[#0052ff] text-white   hover:bg-[#014cec]  py-1 px-4 rounded-3xl mx-2 font-[550]">
                              Sign up
                            </button>
                          </NavLink>
                        </div>
                      )}
                      {/* Profile dropdown */}
                      {openJobPublicInterestModal && (
                        <PublicInterestModal
                          open={openJobPublicInterestModal}
                          setOpen={setOpenJobPublicInterestModal}
                          url={url}
                          token={token}
                          feature={feature}
                          defemail={defemail}
                        />
                      )}
                      <Menu
                        as="div"
                        className="relative ml-3 h-full"
                        ref={dropdownRef}
                      >
                        <div className="flex md:gap-6 gap-4 h-full">
                          {profile && (
                            <div className="h-full flex justify-center items-center focus:outline-none">
                              <NavLink
                                to="/chats"
                                className={({ isActive }) =>
                                  `relative focus:outline-none focus:ring-white hover:text-[#0052ff] ${
                                    isActive
                                      ? "text-[#0052ff]"
                                      : ""
                                  }`
                                }
                                onClick={() => setSelectedUser(null)}
                              >
                                {numberOfNotifications !== 0 && (
                                  <div className="text-[0.6rem] focus:outline-none h-[0.75rem] flex justify-center items-center font-[550] rounded-full aspect-square text-white top-0 right-0 bg-red-600 absolute">
                                    {numberOfNotifications}
                                  </div>
                                )}
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">View Chats</span>
                                <div className="flex flex-col items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-7"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                    />
                                  </svg>
                                </div>
                              </NavLink>
                            </div>
                          )}
                          {profile && (
                            <div className="h-full flex justify-center  items-center focus:outline-none">
                              <NavLink
                                to="/network/invitations" 
                                className={({ isActive }) =>
                                  `relative focus:outline-none focus:ring-white hover:text-[#0052ff] ${
                                    isActive
                                      ? "text-[#0052ff] "
                                      : ""
                                  } ${location.pathname.startsWith("/network/") ? "text-[#0052ff] " : ""}`
                                }
                              >
                                { newreqs != 0 && (
                                  <div className="text-[0.6rem] focus:outline-none h-[0.75rem] flex justify-center items-center font-[550] rounded-full aspect-square text-white  top-0 right-0 bg-red-600 absolute">
                                    {newreqs}
                                  </div>
                                )}
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">View Requests</span>
                                <div className="flex flex-col items-center">
                                  <MdOutlineGroup
                                    className="h-8 w-8 text-center focus:outline-none"
                                    aria-hidden="true"
                                  />
                                </div>
                              </NavLink>
                            </div>
                          )}
                          {profile && (
                            <div className="h-full flex justify-center items-center">
                              <button
                                onClick={toggleServicesDropdown}
                                type="button"
                                className={`relative focus:outline-none ${
                                  isServicesDropDown && "focus:text-[#0052ff]"
                                } hover:text-[#0052ff] `}
                              >
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">View Services</span>
                                <div className="flex flex-col items-center">
                                  <PiDotsNineBold
                                    className="h-8 w-8"
                                    aria-hidden="true"
                                  />
                                  {/* <div className="text-xs text-center md:inline-block hidden">
                                    Services
                                  </div> */}
                                </div>
                              </button>
                              {isServicesDropDown && (
                                <div
                                  className={`dropdown-content absolute z-10 top-[100%] right-[30%] lg:w-[20vw] md:w-[33vw] w-[60vw] border-[1.5px] rounded-lg ${
                                    isDarkMode
                                      ? "bg-[#3b4354] border-[#3b4354]"
                                      : "bg-white"
                                  } `}
                                >
                                  {services.map((service) => (
                                    <div
                                      key={service.id}
                                      className="request-item px-2 text-center hover:bg-blue-100 hover:border-blue-100 cursor-pointer"
                                      onClick={() => {
                                        if (service.message === "Learning") {
                                          handleJobPublicInterestModal(
                                            "Learning"
                                          );
                                        } else if (service.message === "Jobs") {
                                          handleJobPublicInterestModal("Jobs");
                                        }
                                        setIsServicesDropDown(
                                          !isServicesDropDown
                                        );
                                      }}
                                    >
                                      <div
                                        className={`p-2 flex justify-between text-sm`}
                                      >
                                        {service.message}

                                        <Badge
                                          color="primary"
                                          className="text-[10px] "
                                        >
                                          Coming Soon
                                        </Badge>
                                      </div>
                                      <hr
                                        color={isDarkMode ? "bg-gray-500" : ""}
                                      />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                          {profile && (
                            <Menu.Button
                              className="relative flex rounded-full flex-col items-center justify-center  text-sm focus:outline-none"
                              onClick={toggleProfileDropDown}
                            >
                              {/* <div className="flex flex-col items-center h-full"> */}
                              <img
                                className="h-8 w-8 rounded-sm "
                                src={img}
                                alt=""
                              />
                              {/* <div className="text-xs text-center md:inline-block hidden">
                                Me
                              </div> */}
                              {/* </div> */}
                            </Menu.Button>
                          )}
                          {loginbuttons && (
                            <div className=" inset-y-0 flex items-center sm:hidden">
                              {/* Mobile menu button*/}

                              <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="absolute -inset-0.5" />
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                  <XMarkIcon
                                    className="block h-8 w-8"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <Bars3Icon
                                    className="block h-8 w-8"
                                    aria-hidden="true"
                                  />
                                )}
                              </Disclosure.Button>
                            </div>
                          )}
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className={`absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                              isDarkMode
                                ? "bg-[#3b4354] text-white"
                                : "bg-white"
                            }`}
                          >
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    navigate(`/` + purl);
                                  }}
                                  // to={`/` + purl}
                                  className={classNames(
                                    "block px-4 py-2 text-sm cursor-pointer",
                                    active &&
                                      (isDarkMode
                                        ? " bg-[#0052ff] border-[#0052ff]"
                                        : "bg-blue-100 border-blue-100")
                                  )}
                                >
                                  Profile
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to="/documents"
                                  className={classNames(
                                    "block px-4 py-2 text-sm",
                                    active &&
                                      (isDarkMode
                                        ? " bg-[#0052ff] border-[#0052ff]"
                                        : "bg-blue-100 border-blue-100")
                                  )}
                                >
                                  Documents
                                </NavLink>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={"/settings"}
                                  className={classNames(
                                    "block px-4 py-2 text-sm",
                                    active &&
                                      (isDarkMode
                                        ? " bg-[#0052ff] border-[#0052ff]"
                                        : "bg-blue-100 border-blue-100")
                                  )}
                                >
                                  Settings
                                </NavLink>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to="/"
                                  className={classNames(
                                    "block px-4 py-2 text-sm",
                                    active &&
                                      (isDarkMode
                                        ? " bg-[#0052ff] border-[#0052ff]"
                                        : "bg-blue-100 border-blue-100")
                                  )}
                                  onClick={handleSignout}
                                >
                                  Sign out
                                </NavLink>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden ">
                  <div className="space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                      <NavLink key={item.name} to={item.to}>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? "bg-[#0052ff] text-white"
                              : "text-black",
                            "block rounded-md px-3 py-1 text-base font-[550] w-full"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      </NavLink>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
}
