import React, { useState } from "react";
import { LuPencilLine } from "react-icons/lu";
import EditUrlModal from "./EditUrlModal";
import EditCurrLoc from "./EditCurrLoc";
export default function URLComponent({
  urlsubmit,
  curraddsubmit,
  isDarkMode,
  url,
  curradd,
  publicview,
  connectionres,
}) {
  const [viewediturl, setviewediturl] = useState(false);
  const handleviewurlModel = () => {
    setviewediturl(!viewediturl);
  };
  const [viewEditCurrLoc, setviewEditCurrLoc] = useState(false);
  const handleEditCurrLocModel = () => {
    setviewEditCurrLoc(!viewEditCurrLoc);
  };

  // // // console,log(connectionres)
  return (
    <div className="">
      {!publicview && (
        <div
          className={`w-full p-2 border-[1px]  md:rounded-md    ${
            isDarkMode ? "bg-[#3b4354] text-white" : "bg-white"
          }`}
        >
          <div>
            {viewediturl && (
              <EditUrlModal
                url={url}
                urlsubmit={urlsubmit}
                viewediturl={viewediturl}
                setviewediturl={setviewediturl}
                handleviewurlModel={handleviewurlModel}
                isDarkMode={isDarkMode}
              />
            )}
            {viewEditCurrLoc && (
              <EditCurrLoc
                viewEditCurrLoc={viewEditCurrLoc}
                curraddsubmit={curraddsubmit}
                setviewEditCurrLoc={setviewEditCurrLoc}
                handleEditCurrLocModel={handleEditCurrLocModel}
                isDarkMode={isDarkMode}
                curradd={curradd}
              />
            )}
            <div>
              <div>
                <div className="  flex justify-between px-2 items-center">
                  {" "}
                  <div className="font-[550] text-lg">Your profile URL</div>
                  <div
                    className=" p-2 hover:bg-gray-300 cursor-pointer duration-300 rounded-full"
                    onClick={handleviewurlModel}
                  >
                    <LuPencilLine size={20} />
                  </div>
                </div>
                <div>
                  <div
                    className="px-2 py-2 text-sm text-gray-500"
                    style={{
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    www.aspireup.in/{url}
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="text-lg flex justify-between p-2 items-center">
                  {" "}
                  <div className="font-[550]">Your Location</div>
                  <div
                    className=" p-2 hover:bg-gray-300 cursor-pointer duration-300 rounded-full"
                    onClick={handleEditCurrLocModel}
                  >
                    <LuPencilLine size={20} />
                  </div>
                </div>
                <div>
                  <div className="px-2 text-sm text-gray-500">
                    <div>{curradd.streetAddress}</div>
                    <div>{curradd.society}</div>
                    <div>{curradd.city}</div>
                    <div>
                      {curradd.country} {curradd.pincode}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
