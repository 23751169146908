import React from "react";
import { LuPencilLine } from "react-icons/lu";
import { GoPlus } from "react-icons/go";

export default function SkillsComponent({
  dash,
  setshowskills,
  showskills,
  publicview,
  isDarkMode
}) {
  return (
    <div className={`w-full  border-[1px] px-4 pt-3 md:pt-5 md:px-6 md:rounded-md  ${isDarkMode? "bg-[#3b4354]": "bg-white"}`}>
    <div className="text-lg font-[550]  flex justify-between">
        Skills{" "}
        {!publicview && (
          <div className="flex gap-4 ">
            <div onClick={() => setshowskills(!showskills)} className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer">
             
                <GoPlus />
            </div>
          </div>
        )}
      </div>
      <ul className="flex flex-wrap gap-2  list-none">
        {dash.skills.length != 0 ? (
          dash.skills.map((item, key) => (
            <li
              className=" border-gray-100 hover:bg-slate-100 hover:shadow-lg text-sm duration-300 cursor-pointer border-2 p-2 rounded-2xl"
              key={key}
            >
              {item} 
            </li>
          ))
        ) : (
          <div className="text-center text-gray-500 w-full"> No Skills mentioned </div>
        )}
      </ul>
      <br />
    </div>
  );
}
