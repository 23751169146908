import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import countriesList from "../../asserts/Countrylist";
import Login from "../../pages/Login";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";

export default function Profile3({ formdetails, isDarkMode }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setselectedCountry] = useState("");
  const [filteredCounties, setfilteredCounties] = useState([]);

  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCounties.length > 0) {
        const dropdown = document.getElementById("country");
        if (dropdown && !dropdown.contains(event.target)) {
          setfilteredCounties([]);
        }
      }
    };

    const handleEscapeKey = (event) => {
      // Close the dropdown if the "Escape" key is pressed
      if (event.key === "Escape") {
        setfilteredCounties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredCounties]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const verifyDetails = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofile + `${token}`
        );
        // // // console,log(response.data);
        if (response.data.existing_profile.Location.Country != " ") {
          // navigate("/")
        }
      } catch (error) {
        // // // console,log(error);
      }
    };

    verifyDetails();
  }, []);

  const submitform = async (data) => {
    setLoading(true);
    formdetails.Location = data;
    //// // // console,log(data)
    //// // // console,log(formdetails)
    data.Country = selectedCountry;
    const reqbody = {
      Location: data,
      profilestatus: "/signup/add-education",
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      //// // // console,log(response)
      if (response.status == 200) {
        //// // // console,log(response.body);
        navigate(`/signup/profilepicture`);
      }
    } catch (err) {
      //// // // console,log(err);
    }
    // //// // // console,log(data);
    setLoading(false);
    window.scroll(0, 0);
  };

  if (token == null) {
    return <Login />;
  } else {
    return (
      <div>
        <div className=" h-1 w-full flex">
          <div className="h-full bg-[#0052ff] w-[37.5%]"></div>
          <div className="h-full "></div>
        </div>
        <div className="m-4 ">
          <div className="py-3 text-2xl font-[550] ">
            Where are you located?
          </div>
          <div>This helps match you with nearby jobs</div>
          <br />
          <form
            onSubmit={handleSubmit(submitform)}
            className=" flex flex-col gap-2"
          >
            <div className="dropdowninput">
              <div>
                <label htmlFor="city" className="font-[550]">
                  Country
                  {/* <span className="text-red-500">*</span> */}
                </label>
                <div className="w-[100%] relative">
                  <div>
                    <input
                      type="text"
                      className={`w-full p-2 border-[1.5px] rounded-md my-2 `}
                      name="city"
                      value={selectedCountry}
                      {...register("Country", {
                        maxLength: {
                          value: 100,
                          message: "Country must not exceed 100 characters",
                        },
                        pattern: {
                          value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                          message:
                            "Country can only contain alphanumeric characters, spaces, underscores, and hyphens",
                        },
                      })}
                      onChange={handleCityChange}
                    />
                    {errors.Country && (
                      <p className="text-red-500 text-sm">
                        {errors.Country.message}
                      </p>
                    )}
                    {/* Dropdown menu for filtered cities */}
                    {filteredCounties.length > 0 && (
                      <div
                        id="country"
                        className="absolute z-10 mt-1 bg-white border w-[100%] max-h-[150px] overflow-y-auto   rounded-md shadow-lg"
                      >
                        {filteredCounties.map((city, index) => (
                          <div
                            key={index}
                            className="cursor-pointer hover:bg-gray-100 p-2"
                            onClick={() => handleCitySelect(city)}
                          >
                            {city}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="streetadd" className=" font-[550]">
                Street address{" "}
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="streetadd"
                {...register("StreetAddress", {
                  maxLength: {
                    value: 100,
                    message: "Street address must not exceed 100 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                    message:
                      "Street address can only contain alphanumeric characters, spaces, underscores, and hyphens",
                  },
                })}
              />
              {errors.StreetAddress && (
                <p className="text-red-500 text-sm">
                  {errors.StreetAddress.message}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="streetadd" className=" font-[550]">
                Society or community
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="streetadd"
                {...register("society", {
                  maxLength: {
                    value: 100,
                    message: "Society must not exceed 100 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                    message:
                      "Society can only contain alphanumeric characters, spaces, underscores, and hyphens",
                  },
                })}
              />
              {errors.society && (
                <p className="text-red-500 text-sm">{errors.society.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="city" className=" font-[550]">
                City
                {/* <span className=" text-red-500">*</span> */}
              </label>
              <input
                type="text"
                //   placeholder="Last Name"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="city"
                // required
                {...register("City", {
                  maxLength: {
                    value: 100,
                    message: "City must not exceed 100 characters",
                  },
                  pattern: {
                    value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                    message:
                      "City can only contain alphanumeric characters, spaces, underscores, and hyphens",
                  },
                })}
              />
              {errors.City && (
                <p className="text-red-500 text-sm">{errors.City.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="pincode" className=" font-[550]">
                Pincode
              </label>
              <input
                type="number"
                //   placeholder="Last Name"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="pincode"
                {...register("PinCode")}
              />
            </div>
            <div className="flex flex-row-reverse  w-full justify-between">
              {loading ? (
                <FaSpinner size={30} className="text-[#0052ff] animate-spin" />
              ) : (
                <div>
                  {/* <button
                    onClick={() => {
                      navigate("/profile/page1");
                      window.scroll(0, 0);
                    }}
                    className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                  >
                    Back
                  </button> */}
                  <button
                    type="submit"
                    className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                  >
                    Next
                  </button>
                </div>
              )}

              <button
                className="text-[#0052ff]  font-[550] "
                onClick={() => {
                  navigate("/signup/profilepicture");
                  window.scroll(0, 0);
                }}
              >
                Skip
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
