import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiUrl from "../apiConfig";
import { useForm } from "react-hook-form";
import { Upload } from "keep-react";

export default function AddResumeModal({
  showaddresumemodal,
  setShowaddresumemodal,
  isDarkMode,
}) {
  const [fileName, setFileName] = useState("");
  const { control, handleSubmit, setValue } = useForm();
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  // const { handleSubmit, register, formState: errors } = useForm();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const closemodal = () => {
    setShowaddresumemodal(!showaddresumemodal);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      // Set the value of the 'file' field in the form
      setValue("file", file);
    }
  };

  // const onFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  // };

  const [err, seterr] = useState("");
  const validateFile = (data) => {
    if (data.file === undefined) {
      return true;
    }
    if (data.file.type === "application/pdf") {
      return false;
    }
    return true;
  };
  const onFileSubmit = async (data) => {
    if (validateFile(data)) {
      seterr("invalid file format");
      return;
    }
    // // // console,log("Hello");
    // // // console,log(fileName);
    // // // console,log(data.file);
    // setLoading(true);
    try {
      const formData = { sectionName: "Resume" };
      formData["file"] = data.file;
      // formData.append('file', data.file);
      // Get the current date
      const currentDate = new Date();

      // Append the current date to the form data
      formData["createdDate"] = currentDate.toISOString();

      // // // console,log(formData);
      // Send the file to the backend using Axios
      const response = await axios.post(
        `${apiUrl.profile.addresume}${token}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const result = response.data;
        // formdetails.uploadedFile = result.link; // Adjust based on your backend response
        // setLoading(false);
        setShowaddresumemodal(!showaddresumemodal);
      } else {
        console.error("File upload failed");
        // Handle the error as needed
      }
    } catch (error) {
      console.error("Error uploading file", error);
      // Handle the error as needed
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setShowaddresumemodal(!showaddresumemodal)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                      isDarkMode
                        ? "bg-[#2a303c] border-[#2a303c]"
                        : "bg-white text-black"
                    }`}
                  >
                    <div className="flex w-full items-end justify-between py-2 cursor-pointer">
                      <div className="text-2xl font-[550]">Upload Resume</div>
                      <IoMdClose
                        size={30}
                        onClick={closemodal}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      />
                    </div>{" "}
                    {/* <div className=" h-1 w-full flex">
                    <div className="h-full bg-blue-900 w-[16%]"></div>
                    <div className="h-full  w-[84%]"></div>
                </div> */}
                    <div className="py-2">
                      Upload your resume to enhance your profile and make it
                      easier for potential employers to discover your skills and
                      qualifications. Choose the file from your device and
                      ensure it is in a compatible format.
                    </div>
                    <div className=" relative">
                      <div>
                        <form
                          onSubmit={handleSubmit(onFileSubmit)}
                          className="flex flex-col justify-center"
                        >
                          <Upload
                            className=""
                            fileType="Files accepted: .pdf"
                            title="Click here to upload file"
                            required
                            onFileChange={(file) => {
                              handleFileChange(file); // Call your handleFileChange function with the file object
                            }}
                            file={fileName}
                          />
                          {err && (
                            <p className="text-center text-red-500 ">
                              *File upload error. Please upload the file or
                              check the file type.*
                            </p>
                          )}
                          {/* {fileName && (
                        <div className="mb-4">
                        <embed
                        src={URL.createObjectURL(fileName)}
                        width="100%"
                            height="500"
                            />
                        </div>
                    )} */}
                          {/* {!validateFile() && (
                        <p className="text-red-500">Please enter a valid PDF file.</p>
                    )} */}
                          <div className="flex flex-row-reverse">
                            <button
                              type="submit"
                              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                            >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
