import React, { useEffect } from "react";
import apiUrl from "../../apiConfig";
import axios from "axios";
import { useState } from "react";
import ProfileTile from "./ProfileTile";
import DefaultCompany from "../../images/DefaultCompany.png";
import DefaultEducation from "../../images/DefaultEducation.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

export default function SelectedTagUsers({
  activetag,
  setActivetag,
  refresh,
  setrefresh,
  currentUserId,
}) {
  const token = localStorage.getItem("token");
  const [tagUsers, setTagUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const findimage = (name, type) => {
    const company = companies.find((obj) => obj.Name === name);
    const comimage = company ? company.s3Link : DefaultCompany;
    const university = universities.find((obj) => obj.Name === name);
    const schimage = university ? university.s3Link : DefaultEducation;
    return type === "job" ? comimage : schimage;
  };

  const handleUntag = async (url) => {
    try {
      const response = await axios.post(apiUrl.tags.untaguser + token, {
        TagName: activetag.TagName,
        url: url,
      });
      if (response.status === 200) {
        // Update the state correctly by filtering out the untagged user
        setTagUsers((prevTagUsers) =>
          prevTagUsers.filter((user) => user.url !== url)
        );
      }
    } catch (error) {
      // console,log(error);
    }
  };

  function encrypt(string1, string2, shift) {
    // Use a unique delimiter that won't appear in the original strings
    const delimiter = "|";

    // Concatenate the two strings with the delimiter
    let concatenatedString = string1 + delimiter + string2;

    // Encrypt the concatenated string with a shift cipher
    let encryptedString = "";

    for (let i = 0; i < concatenatedString.length; i++) {
      let charCode = concatenatedString.charCodeAt(i);

      // Shift the character code
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 65 + shift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 97 + shift) % 26) + 97
        );
      } else {
        encryptedString += concatenatedString[i]; // Non-alphabetical characters
      }
    }

    return encryptedString;
  }

  const navigateToChats = (item) => {
    // console,log(item, currentUserId);
    const chaturl = encrypt(item.UserId, currentUserId, 7);
    navigate("/chats/" + chaturl);
  };

  const disconnect = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setTagUsers((prevTagUsers) =>
          prevTagUsers.filter((user) => user.url !== url)
        );
        setrefresh(!refresh);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  useEffect(() => {
    // console,log(activetag);
    const users = activetag.TagUsers;
    const fetchTagUsers = async (id) => {
      try {
        // console,log(id);
        const response = await axios.post(apiUrl.profile.getprofilefromid + id);
        const newProfile = {
          profilepic: response.data.profile.Profilepic,
          Name:
            response.data.profile.FullName.FirstName +
            " " +
            response.data.profile.FullName.LastName,
          currentRole: response.data.profile.currentRole,
          latestEducation: response.data.profile.LatestEducation,
          latestExperience: response.data.profile.LatestExperience,
          Location: response.data.profile.Location,
          url: response.data.url,
          UserId: id
        };
        // console,log(newProfile);
        setTagUsers((prev) => [...prev, newProfile]);
      } catch (error) {
        // console,log(error);
      }
    };
    for (let i = 0; i < users.length; i++) {
      fetchTagUsers(users[i].UserId);
    }
  }, []);
  return (
    <div className="bg-white w-full border-[1px]  md:rounded-md p-4">
      <div
        className=" flex items-center mb-4 cursor-pointer w-fit"
        onClick={() => setActivetag(null)}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>

        <h1 className=" text-xl font-[550] inline-block">
          {activetag.TagName}
        </h1>
      </div>

      {tagUsers.length !== 0 ? (
        tagUsers.map((user, index) => (
          <div
            key={index}
            className={` ${
              index === tagUsers.length - 1 ? "" : "border-b-[1px]"
            } p-2 h-full flex md:flex-row flex-col justify-between md:items-start py-2 w-full`}
          >
            <ProfileTile user={user} findimage={findimage} />
            {/* <div className=" text-gray-500 border-[1.5px] w-fit border-gray-500 hover:bg-gray-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl my-2 md:my-0 h-fit" onClick={() => handleUntag(user.url)}>
              Untag
            </div> */}
            <div
              className="duration-300 font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025] mx-2"
              onClick={() => navigateToChats(user)}
            >
              Message
            </div>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className=" duration-300   font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs border-black hover:bg-[#80808025]">
                  ···
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                  <div className="py-1" onClick={() => handleUntag(user.url)}>
                    <Menu.Item>
                      <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                        Untag
                      </div>
                    </Menu.Item>
                  </div>
                  <div className="py-1" onClick={() => disconnect(user.url)}>
                    <Menu.Item>
                      <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                        Remove Connection
                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        ))
      ) : (
        <div className=" text-base font-[550] text-center w-full  pb-2">
          no users tagged{" "}
        </div>
      )}
    </div>
  );
}
