"use client";
import { Accordion } from "keep-react";

export const Accordioncomponent = () => {
  return (
    <Accordion flush={true}>
      <Accordion.Panel className=" bg-[#f7f9fa] ">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className="text-black">
             Basics, Complexity Analysis, Bit Manipulation, Recursion
            </div>
            </div>
          </Accordion.Title>
          <Accordion.Icon />
        </Accordion.Container>
        <Accordion.Content>
          <div className=" flex justify-evenly flex-col md:flex-row gap-4 ">
            <div className=" text-black bg-white p-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Basic of Programming </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>32 vs 64 bit</li>
                    <li>Decimal - Binary conversion</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Data types & operators</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Range of data types</li>
                    <li>Types of operators</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">
                    Complexity Analysis of Algorithms{" "}
                  </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Big-O Notation</li>
                    <li>Time & Space</li>
                    <li>Importance of Contraints</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Bit Manipulation</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Bitwise operators</li>
                    <li>Bit-masking</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Modular Arithmetic </div>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Recursion</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Thinking Recusively</li>
                    <li>Recurrence Relations</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Backtracking</div>
                </li>
              </ul>
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" bg-[#f7f9fa] ">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className="text-black">
             Sorting, Searching, Hashing, Strings, Mixed-Bag
            </div>
            </div>
          </Accordion.Title>
          <Accordion.Icon />
        </Accordion.Container>
        <Accordion.Content>
          <div className=" flex justify-evenly flex-col md:flex-row gap-4 pb-4">
            <div className=" text-black bg-white p-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">
                    Sorting Techniques & Applications
                  </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Standard sorting algorithms</li>
                    <li>Applications of Count Sort</li>
                    <li>Inversion Count</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Two pointer technique</div>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Master's Theorem</div>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Searching Techniques</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Linear Search</li>
                    <li>Binary Search</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">
                    Applications of Binary Search
                  </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li> Lower Bound & Upper Bound</li>
                    <li>Finding Frequency</li>
                    <li>Optimisation problems</li>
                    <li>Root of a number</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Hashing</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Why hashing?</li>
                    <li>Hashing Techniques</li>
                    <li>Collision Resolutions</li>
                    <li>Inbuilt Libraries</li>
                  </ul>
                </li>

                <li className="pb-2">
                  <div className="font-[550]">Maps and Sets</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Syntax and Usage</li>
                    <li>Time Complexities</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className=" flex justify-evenly flex-col md:flex-row gap-4 ">
            <div className=" text-black bg-white p-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Arrays</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Generating Subarrays</li>
                    <li>Subsets vs Subsequences</li>
                    <li>Sliding Window Technique</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Strings</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>ASCII representation</li>
                    <li>Rolling Hash</li>
                    <li>Substring matching</li>
                    <li>Rabin-Karp</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Mixed-bag problem-solving</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Arrays & matrices</li>
                    <li>Sorting / Searching / Hashing</li>
                    <li>Bit-manipulation Space optimizations</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Prefix-sum</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li> Precomputation</li>
                    <li> Index marking</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Prime Numbers</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Sieve of Eratosthenes</li>
                    <li>Segmented Sieve</li>
                  </ul>
                </li>

                <li className="pb-2">
                  <div className="font-[550]">Miscellaneous Concepts</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Game Theory</li>
                    <li>Meet in the Middle</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" bg-[#f7f9fa] ">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className="text-black">
            Stacks, Queues, Linked Lists, Trees, Heaps, Tries
            </div>
            </div>
          </Accordion.Title>
          <Accordion.Icon />
        </Accordion.Container>
        <Accordion.Content>
          <div className=" flex justify-evenly flex-col md:flex-row gap-4 pb-4">
            <div className=" text-black bg-white p-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Stacks and Queues</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Array representations</li>
                    <li>Multiple stacks in an array</li>
                    <li>Implement queue using stacks</li>
                    <li>Circular Queue</li>
                    <li>Doubly Ended Queue</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">
                    {" "}
                    Amortized Analysis of Algorithms
                  </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Dynamic Arrays</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Basics of Linked-Lists </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Insert/Search/Delete/Reverse</li>
                    <li>Sorting on Linked-Lists</li>
                    <li>Tortoise & Hare Algorithm</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Advanced problems on LL</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Intersection point</li>
                    <li>Cycle Detection</li>
                    <li>Cloning a Linked-List</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">LRU Cache</div>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">Basics of Trees </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Tree Traversals</li>
                    <li>Height and Depth</li>
                    <li>CBT and FBT</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]"> Binary Search Trees</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Insert/Search/Delete</li>
                    <li>Problems on BST</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Advanced problems on Trees</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Views / K-Distance / Max Sum Path</li>
                    <li>Iterative Tree Traversals</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className=" flex justify-evenly flex-col md:flex-row gap-4 ">
            <div className=" text-black bg-white p-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] "> Priority Queues</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Min/Max Heap - CBT</li>
                    <li>Median of subarrays</li>
                    <li>External Sort</li>
                    <li>Heapify</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]"> Trie DS and Applications</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Dictionary & Prefix Matching</li>
                    <li>Bit-manipulation with Trie</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" bg-[#f7f9fa] ">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className="text-black">
             Dynamic Programming and Graph Theory
            </div>
            </div>
          </Accordion.Title>
          <Accordion.Icon />
        </Accordion.Container>
        <Accordion.Content>
          <div className=" flex justify-evenly flex-col md:flex-row gap-4 ">
            <div className=" text-black bg-white p-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">
                    Basics of Dynamic Programming{" "}
                  </div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Memoization vs Tabulation</li>
                    <li>Applications of Fibonacci</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Subarrays and Subsequences</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Maximum Subarray Sum</li>
                    <li>Non-adjacent Subsequence</li>
                    <li>Longest Increasing Subsequence</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Miscellaneous Problems on DP</div>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">The Knapsack Problem</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Computing nCr</li>
                    <li>Fractional and Integer</li>
                    <li>Applications of Knapsack</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Matrix DP</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Number of Ways</li>
                    <li>Submatrix Sum</li>
                    <li>Matrix Chain Multiplication</li>
                    <li>Optimal Strategy Game</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className=" text-black bg-white p-4 px-4 md:w-1/3 w-full rounded-md shadow-[3px_3px_7px_5px_rgba(186,186,186,0.3)]">
              <ul className=" list-decimal px-2">
                <li className="pb-2">
                  <div className="font-[550] ">
                    Introduction to Graph Theory{" "}
                  </div>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Basics of Graph Theory</div>
                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Traversals - BFS & DFS</li>
                    <li>Cycle Detection</li>
                  </ul>
                </li>
                <li className="pb-2">
                  <div className="font-[550]">Important Graph Algorithms</div>

                  <ul className="text-sm text-gray-700 list-disc px-4">
                    <li>Dijkstra's Algorithm</li>
                    <li>Topological Sorting</li>
                    <li>Kruskal's Algorithm - MST</li>
                    <li>Bipartite Graphs</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};
