import React from "react";
import { GoPlus } from "react-icons/go";
import Certifications from "../Certifications";
import { IoIosArrowUp } from "react-icons/io";
import DefaultCompany from "../../images/DefaultCompany.png";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { useState, useEffect } from "react";

export default function CertificationComponent({
  publicview,
  isDarkMode,
  handleCertificationModal,
  dash,
  api,
  setapi,
  visibleItemsCertificates,
  showallCertificates,
  setshowallCertificates,
}) {
  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
  }, []);


  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;
  
    experience.forEach((item) => {
    
      const role = {
        Name: item.Name,
        issuemonth: item.issuemonth,
        issueyear: item.issueyear,
        expirymonth:item.expirymonth,
        expiryyear: item.expiryyear,
        credentialid: item.credentialid,
        credentialurl: item.credentialurl,
        Skills: item.Skills,
        link: item.link,
        _id: item._id,
      };
      if (lastCompany && lastCompany.Organisation === item.Organisation) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          Organisation: item.Organisation,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });
  
    return result;
  };
  
  const transformedExperience =  transformExperienceData(dash.certifications);
  // // console,log(transformedExperience)
  const visiblejobitem = showallCertificates? transformedExperience : transformedExperience.slice(0, 2);


  return (
    <div
      className={`w-full  border-[1px] px-4 py-3 md:pt-5 md:px-6 md:rounded-md  ${
        isDarkMode ? "bg-[#3b4354]" : "bg-white"
      }`}
    >
      <div className="text-lg font-[550] flex justify-between ">
        Certifications{" "}
        {!publicview && (
          <div className="flex gap-2">
            {" "}
            <div
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
              onClick={handleCertificationModal}
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      <div>
        {visiblejobitem.map((item, key) => {
          const company = companies.find(
            (obj) => obj.Name === item.Organisation
          );
          // Get the image link if the university is found
          const image = company ? company.s3Link : DefaultCompany;

          return (
            <Certifications
              key={key}
              props={{
                token: token,
                item: item,
                publicview: publicview,
                isDarkMode: isDarkMode,
                api: api,
                setapi: setapi,
                length: transformedExperience.length,
                key: key,
                image: image,
              }}
            />
          );
        })}
      </div>
      {transformedExperience.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center  border-slate-300">
          {/* <hr className="w-[30%]" /> */}
          <button
            className=" border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setshowallCertificates(!showallCertificates)}
          >
            {showallCertificates
              ? "Show Less"
              : `Show All ${transformedExperience.length}`}{" "}
            <IoIosArrowUp
              className={showallCertificates ? "rotate-0" : "rotate-180"}
            />
          </button>
          {/* <hr className="w-[30%]" /> */}
        </div>
      )}
    </div>
  );
}
