const skills = [
  "Java",
  "Python",
  "JavaScript",
  "C++",
  "Algorithm design and analysis",
  "Data structures",
  "Object-oriented programming (OOP)",
  "Problem-solving",
  "Software development lifecycle",
  "Version control (e.g., Git)",
  "Debugging",
  "Critical thinking",
  "System design",
  "Databases (SQL and NoSQL)",
  "Operating systems principles",
];

export default skills;
