import React from 'react'
import ReDashboard from './ReDashboard';
import { useState } from 'react';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

export default function ForgotPassword({isDarkMode, url}) {
    const [role, setRole] = useState("jobseeker");

    const token = localStorage.getItem("token");
    //// // console,log(token);
    
    const toggleRole = () => {
      setRole(role === "jobseeker" ? "employer" : "jobseeker");
    };
  
    if(token!=null){
     return  <ReDashboard url={url}/>
    }
    else{
    return (
      <div className="flex justify-center items-center p-3 min-h-[90vh] ">
      <div className="max-w-screen-xl flex justify-center items-center md:w-[65%] w-full">
      <div
            className={`flex flex-col w-[100%] lg:w-[40vw] h-full  border-2 rounded-lg shadow-lg  ${
              isDarkMode
                ? "shadow-[#3b4354] border-[#3b4354]"
                : "shadow-slate-300 "
            } `}
          > {/* <div className="flex flex-row h-[40px]">
            <div
              className={`w-[50%] text-center flex items-center justify-center h-full hover:cursor-pointer rounded-md ${role === "jobseeker" ? "" : "bg-gray-200"}`}
              onClick={role === "jobseeker" ? null : toggleRole}
            >
              Jobseeker
            </div>
            <div
              className={`w-[50%] text-center flex items-center justify-center h-full hover:cursor-pointer rounded-md ${role === "employer" ? "" : "bg-gray-200"}`}
              onClick={role === "employer" ? null : toggleRole}
            >
              Employer
            </div>
          </div> */}
          <div className="h-full">
            <div className="m-4 ">
              {
                role === "jobseeker" &&
              <ForgotPasswordForm isDarkMode={isDarkMode} title='Forgot Password'/>
              }
              {
                role === "employer" && 
                "employer login form"
              }
            </div>
          </div>
        </div>
        </div>
      </div>
    );
            }
  
}
