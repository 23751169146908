import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import axios from "axios";
import apiUrl from "../apiConfig";
export default function ProjectReview({ formdetails, isDarkMode }) {
  const { id } = useParams();
  const navigate = useNavigate();

  // Initialize jobData as an empty array if it's undefined in formdetails
  const token = localStorage.getItem("token");
  const [jobDetails, setJobDetails] = useState([]);
  useEffect(() => {
    const geteducation = async () => {
      const response = await axios.post(apiUrl.profile.getprofile + token);
      if (response.status === 200) {
        // // // console,log(response.data.existing_profile.projects);
        setJobDetails(response.data.existing_profile.projects);
      }
    };

    geteducation();
  }, []);

  const deleteJob = (index) => {
    const updatedJobData = jobDetails.filter((_, i) => i !== index);
    setJobDetails(updatedJobData);
  };

  const submitform = async (data) => {
    const reqbody = {
      projects: jobDetails,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      //// // // console,log(response)
      if (response.status == 200) {
        // //// // // console,log(response.body);
        navigate(`/signup/add-experience`);
      }
    } catch (err) {
      //// // // console,log(err);
    }
    window.scroll(0, 0);
  };

  return (
    <div>
      <div className="h-1 w-full flex">
        <div className="h-full bg-[#0052ff] w-[62.5%]"></div>
        <div className="h-full"></div>
      </div>
      <div className="m-2 p-2">
        <h2 className="py-3 text-2xl font-[550] font-sans text-center">
          Project Review
        </h2>
        <br />
        <ul>
          {jobDetails.map((job, index) => (
            <li
              key={index}
              className="p-4 m-2 rounded-md  flex flex-wrap justify-between "
            >
              <div>
                <h1 className="text-[0.95rem] font-[550] ">{job.Title}</h1>
                <p className="text-sm ">{job.company}</p>
                <p className="text-sm text-gray-400">
                  {" "}
                  {job.fromMonth} {job.fromYear} -{" "}
                  {!job.toMonth ? (
                    <span>Present</span>
                  ) : (
                    <span>
                      {job.toMonth} {job.toYear}
                    </span>
                  )}
                </p>
                <p className=" text-sm">
                  {" "}
                  {job.skills.length !== 0 && (
                    <span className="font-[550]">Skills: </span>
                  )}
                  {job.skills.map((item, key) => (
                    <span key={key}>
                      {item}
                      {item === job.skills[job.skills.length - 1]
                        ? "."
                        : ","}{" "}
                    </span>
                  ))}
                </p>
                {/* <p>Description: </p> */}
                <p className="text-sm text-justify w-full">{job.description}</p>
                <div className="">
                  {job.link && (
                    <a
                    className=" text-blue-500 underline"
                      target="_blank"
                      href={
                        job.link.startsWith("https://")
                          ? job.link
                          : "https://" + job.link
                      }
                    >
                     {job.link}
                    </a>
                  )}
                </div>
              </div>
              <div className="gap-3 flex">
                {/* <button onClick={() => editJob(index)}>
                  <AiFillEdit size={25} />
                </button> */}
                <button onClick={() => deleteJob(index)}>
                  <MdDeleteForever color="red" size={25} />
                </button>
              </div>
            </li>
          ))}
        </ul>
        <br />
        <br />
        <div className="flex justify-between">
          <button
            className="text-[#0052ff] hover:text-[#014cec] font-[550] flex justify-center items-center gap-1"
            onClick={() => navigate(`/signup/add-project`)}
          >
            <IoIosAddCircleOutline size={20} /> Add Project
          </button>
          <button
            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
            onClick={submitform}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
