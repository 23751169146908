import React from "react";

import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function Landingsection4() {
  const navigate = useNavigate();
  return (
    <div className=" text-center flex flex-col justify-center items-center gap-4 p-4 ">
      <div className=" md:text-4xl text-2xl font-[550] ">
        Get Started for free
      </div>
      <div className=" font-[550] text-sm md:text-base">
        unlock success, Absolutely free, Always Accessible
      </div>
      <div
        onClick={() => {
          navigate("/signup");
          window.scroll(0, 0);
        }}
        className=" cursor-pointer w-fit flex bg-black text-white items-center p-2 rounded-lg gap-2 "
      >
        Sign up now <FaArrowRight />
      </div>
      <br />
      <br />
    </div>
  );
}
