"use client";
import { Accordion } from "keep-react";

export const Accordion2 = () => {
  return (
    <Accordion flush={true}>
      <Accordion.Panel className=" border-[1.5px] hover:border-[#0052ff] rounded-md mb-2">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className=" text-black md:text-lg text-base ">
             What is special about this program?
            </div>
            </div>
          </Accordion.Title>
        </Accordion.Container>
        <Accordion.Content>
          <div className=" md:text-base text-sm text-gray-700">
            This program goes beyond helping you master Algorithms and Data
            Structures(A&DS). We provide additional benefits like Success Coach,
            and others that are usually not seen in any other similar
            courses/bootcamps, but are very important to achieve results. Most
            people start the courses and leave them in between. In this program,
            your Success Coach monitors your progress regularly and helps you to
            stay on track with your learning. You don't need to struggle alone
            anymore. We are not just teaching you A&DS, but we are committed to
            helping you in the best possible way to ensure your success. You
            will also get a chance to meet highly motivated peers, and make life
            long connections.{" "}
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" border-[1.5px] hover:border-[#0052ff] rounded-md mb-2">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className=" text-black md:text-lg text-base ">
             I'm good at Data Structures & Algorithms. Is this a right program
              for me?
            </div>
            </div>
          </Accordion.Title>
        </Accordion.Container>
        <Accordion.Content>
          <div className=" md:text-base text-sm text-gray-700">
            Yes! During the course, you will get access to a student portal.
            While you practice questions from different coding platforms, you
            can see the consolidated score in your portal. Even better, you will
            get to see how you are performing compared to other students in the
            class. You will also get to connect with like-minded peers,
            potentially from other schools/companies as well.
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" border-[1.5px] hover:border-[#0052ff] rounded-md mb-2">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className=" text-black md:text-lg text-base ">
             I'm new to Data Structures & Algorithms. Is this is a right
              program for me?
            </div>
            </div>
          </Accordion.Title>
        </Accordion.Container>
        <Accordion.Content>
          <div className=" md:text-base text-sm text-gray-700">
            Absolutely! We recommend that you are familiar with at least one
            programming language (C/C++/Java/Python/C#). If you are from an
            engineering background, you will most likely meet the prerequisites.
            If you don't have any background in programming, we offer a Coding
            101 course at no additional cost. We will support you in all the
            ways to help you get started. All we expect from you is a desire to
            learn and a commitment to succeed through out the program.
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" border-[1.5px] hover:border-[#0052ff] rounded-md mb-2">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className=" text-black md:text-lg text-base ">What is Smart Interviews?</div>
            </div>
          </Accordion.Title>
        </Accordion.Container>
        <Accordion.Content>
          <div className=" md:text-base text-sm text-gray-700">
            Aspireup is partnering with Smart Interviews to ensure that you are
            learning from the top instructors to master Advanced Data Structures
            and Algorithms. Smart Interviews helped over 18000 students and
            professionals to get placed in top companies. Learn more about the
            success stories{" "}
            <a
              href="https://smartinterviews.in/testimonials"
              className="text-[#0052ff] underline"
              target="__blank"
            >
              here
            </a>
            .
          </div>
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel className=" border-[1.5px] hover:border-[#0052ff] rounded-md mb-2">
        <Accordion.Container>
          <Accordion.Title>
            <div className="flex justify-start">
            <div className=" text-black md:text-lg text-base ">
            What if I miss some of the live sessions?
            </div>
            </div>
          </Accordion.Title>
        </Accordion.Container>
        <Accordion.Content>
          <div className=" md:text-base text-sm text-gray-700">
            You will have access to 6 video credits (throughout / after the
            course), 1 credit can be used to watch 1 session video. Video
            Credits will remain available for 180 days from the course start
            date. <br />
            We strongly recommend that you make best efforts to attend all the
            live classes as the focus is on live learning.
          </div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
};
