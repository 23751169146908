const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const year = new Date().getFullYear();
const years = [];
for (let i = year; i > 1980; i--) {
  years.push(i);
}

export default [Months, years];
