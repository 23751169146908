import React, { useState, useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../apiConfig";
import LinkedInImage from "../images/LinkedIn.jpeg";
import GitHubImage from "../images/GitHub.jpeg";
import XImage from "../images/X.jpeg";
import Instagram from "../images/instagram.png";
import Discord from "../images/discord.png";
import Website from "../images/website.png";
import countryData from "../asserts/Countrylist";
export default function EditSocialAcc({
  dash,
  handleEditProfile,
  openModal,
  publicview,
  setpublicview,
  isDarkMode,
  api,
  setapi,
}) {
  const [resumes, setresumes] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const validateAllSocialUrls = () => {
    let result = true;
    Object.entries(duplicateDash || {}).forEach(([key, value]) => {
      if (value !== "" && key !== "_id") {
        switch (key) {
          case "LinkedIn":
            result = /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/.test(value);
            break;
          case "GitHub":
            result = /^https:\/\/github\.com\/[A-Za-z0-9_-]+$/.test(value);
            break;
          case "Twitter":
            result = /^(https:\/\/(?:x\.com|twitter\.com)\/[A-Za-z0-9_-]+)$/.test(value);
            break;
          case "Instagram":
            result = /^https:\/\/instagram\.com\/[A-Za-z0-9_-]+$/.test(value);
            break;
          case "Discord":
            result = /^https:\/\/discord\.com\/[A-Za-z0-9_-]+$/.test(value);
            break;
          case "WebSite":
            result = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/.test(value);
            break;
          default:
            result = false;
        }
        if (!result) {
          return false;
        }
      }
    });
    return result;
  };
  const submitform = async () => {
    // // // console,log(data);
    let data = duplicateDash;
    data[social] = socialUrl;
    const reqbody = {
      socialaccounts: data,
      // Resume: data.Resume,
    };
    console.log(validateAllSocialUrls())
    if (validateAllSocialUrls() == true) {
      try {
        const response = await axios.post(
          apiUrl.profile.editprofile + token,
          reqbody
        );
        //// // // console,log(response);
        if (response.status == 200) {
          //// // // console,log(response.body);
          // Navigate to the next page or wherever you want
          setError("");
          setsocial({});
          setsocialUrl("");
          setMessage("Social accounts updated successfully");
          setOpenSocialInputs(false);
          // handleEditProfile();
          setapi(!api);
        }
      } catch (err) {
        setMessage("");
        setError(err.response.data.message);
        // // console,log(err);
      }
    } else {
      // setOpenSocialInputs(false);
      data[social] = "";
      setMessage("");
      setError(`Please enter valid ${social} url`);
    }
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [actres, setactres] = useState("");
  useEffect(() => {
    const getresumes = async () => {
      try {
        const response = await axios.post(
          apiUrl.documents.getdocuments + `${token}`
        );
        // // // console,log(response.data.documents);
        const sections = response.data.documents;

        const index = sections.findIndex((sec) => sec.Name === "Resume");
        setresumes(sections[index].Documents);
      } catch (err) {}
    };

    const getactres = async () => {
      try {
        const resp = await axios.post(apiUrl.profile.getprofile + token);
        // // // console,log(resp.data.existing_profile.Resume);
        setactres(resp.data.existing_profile.Resume);
      } catch (err) {}
    };
    getactres();
    getresumes();
  }, []);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const token = localStorage.getItem("token");

  const [openSocialInputs, setOpenSocialInputs] = useState(false);
  const [social, setsocial] = useState({});
  const [socialUrl, setsocialUrl] = useState("");
  const [duplicateDash, setDuplicateDash] = useState(dash.socialaccounts);

  const handleOpenSocialInputs = () => {
    if (
      dash.socialaccounts.LinkedIn &&
      dash.socialaccounts.Github &&
      dash.socialaccounts.Twitter &&
      dash.socialaccounts.Instagram &&
      dash.socialaccounts.Discord &&
      dash.socialaccounts.WebSite
    ) {
      setOpenSocialInputs(false);
      return;
    }
    setOpenSocialInputs(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleEditProfile}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">
                      Edit Social Details
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={handleEditProfile}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  {Object.values(dash.socialaccounts).some(
                    (val) => val === ""
                  ) && (
                    <div
                      className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl my-2 md:my-0 w-fit"
                      onClick={handleOpenSocialInputs}
                    >
                      Add Social Account
                    </div>
                  )}

                  <div className="my-2 overflow-auto">
                    {Object.entries(duplicateDash).map(([key, value]) => {
                      if (value !== "" && key != "_id") {
                        const newSocial = { ...duplicateDash };
                        return (
                          <div className="flex flex-col gap-2 border p-2 rounded-md my-2">
                            <div className="flex flex-col">
                              <label htmlFor="Social" className=" font-[550]">
                                Social Profile Name
                              </label>
                              <input
                                className={`w-full p-2 border-[1.5px]  rounded-md ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : ""
                                }`}
                                name={key}
                                value={key}
                                disabled
                                onChange={(e) => setsocial(e.target.value)}
                              />
                            </div>
                            <div className="flex flex-col">
                              <label htmlFor="Social" className=" font-[550]">
                                URL
                              </label>
                              <input
                                type="text"
                                placeholder="Enter URL"
                                className="w-full p-2 border-[1.5px]  rounded-md"
                                value={value}
                                onChange={(e) =>
                                  setDuplicateDash((prevState) => ({
                                    ...prevState,
                                    [key]: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <div className="flex gap-2 justify-end ">
                              <button
                                className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                                onClick={() =>{
                                  setDuplicateDash((prevState) => ({
                                    ...prevState,
                                    [key]: "",
                                  }))
                                  setsocial(key)
                                }
                                }
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {openSocialInputs && (
                      <div className="flex flex-col gap-2 border p-2 rounded-md">
                        <div className="flex flex-col">
                          <label htmlFor="Social" className=" font-[550]">
                            Social Profile Name
                          </label>
                          <select
                            className={`w-full p-2 border-[1.5px]  rounded-md ${
                              isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                            }`}
                            name="Social"
                            // required

                            value={social}
                            onChange={(e) => setsocial(e.target.value)}
                          >
                            <option value="">Select a Social Profile</option>
                            {!dash.socialaccounts.LinkedIn && (
                              <option value="LinkedIn">
                                <div className="flex items-center gap-2">
                                  <img src={LinkedInImage} alt="LinkedIn" className="w-6 h-6" />
                                  <span>LinkedIn</span>
                                </div>
                              </option>
                            )}
                            {!dash.socialaccounts.Twitter && (
                              <option value="Twitter">
                                <div className="flex items-center gap-2">
                                  <img src={XImage} alt="Twitter" className="w-6 h-6" />
                                  <span>X(Twitter)</span>
                                </div>
                              </option>
                            )}
                            {!dash.socialaccounts.WebSite && (
                              <option value="WebSite">
                                <div className="flex items-center gap-2">
                                  <img src={Website} alt="WebSite" className="w-6 h-6" />
                                  <span>WebSite</span>
                                </div>
                              </option>
                            )}
                            {!dash.socialaccounts.GitHub && (
                              <option value="GitHub">
                                <div className="flex items-center gap-2">
                                  <img src={GitHubImage} alt="GitHub" className="w-6 h-6" />
                                  <span>GitHub</span>
                                </div>
                              </option>
                            )}
                            {!dash.socialaccounts.Instagram && (
                              <option value="Instagram">
                                <div className="flex items-center gap-2">
                                  <img src={Instagram} alt="Instagram" className="w-6 h-6" />
                                  <span>Instagram</span>
                                </div>
                              </option>
                            )}
                            {!dash.socialaccounts.Discord && (
                              <option value="Discord">
                                <div className="flex items-center gap-2">
                                  <img src={Discord} alt="Discord" className="w-6 h-6" />
                                  <span>Discord</span>
                                </div>
                              </option>
                            )}
                          </select>
                        </div>
                        
                        <div className="flex flex-col">
                          <label htmlFor="Social" className=" font-[550]">
                            URL
                          </label>
                          <input
                            type="text"
                            placeholder="Enter URL"
                            className="w-full p-2 border-[1.5px]  rounded-md"
                            value={socialUrl}
                            onChange={(e) => setsocialUrl(e.target.value)}
                          />
                        </div>

                        <div className="flex gap-2 justify-end ">
                          {/* <button
                            className="bg-[#0052ff] text-white w-fit hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                            onClick={submitform}
                          >
                            Save
                          </button> */}
                          <button
                            className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                            onClick={() => setOpenSocialInputs(false)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                    {message && <p className="text-green-500">{message}</p>}
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="flex gap-2 justify-end mt-2">
                      <button
                        className="bg-[#0052ff] text-white w-fit hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        onClick={submitform}
                      >
                        Save
                      </button>
                    </div>

                    {/* <form
                      action=""
                      onSubmit={handleSubmit(submitform)}
                      className=" flex flex-col gap-2"
                    >
                      <div className="">
                        <label htmlFor="Resume" className=" font-[550]">
                          Resume
                        </label>
                        <select
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="country"
                          // required
                          {...register("Resume")}
                          value={actres}
                          onChange={(e) => setactres(e.target.value)}
                        >
                          <option value="">Select a Resume</option>
                          {resumes.map((country, index) => (
                            <option key={index} value={country.Link}>
                              {country.Name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label htmlFor="firstname" className=" font-[550]">
                          LinkedIn
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354]" : ""
                          }`}
                          name="LinkedIn"
                          defaultValue={dash.socialaccounts.LinkedIn}
                          {...register("LinkedIn", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/,
                              message: "Invalid LinkedIn URL",
                            },
                          })}
                        />
                        {errors.LinkedIn && (
                          <p className="text-red-500">
                            {errors.LinkedIn.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="City" className="font-[550]">
                          X (Twitter)
                        </label>
                        <input
                          type="text"
                          //   placeholder="Last Name"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="currentRole"
                          defaultValue={dash.socialaccounts.Twitter}
                          {...register("Twitter", {
                            pattern: {
                              value:
                                /^((https:\/\/)?(www\.)?(?:x\.com|twitter\.com)\/[A-Za-z0-9_-]+)$/,
                              message: "Invalid X profile link",
                            },
                          })}
                        />
                        {errors.Twitter && (
                          <p className="text-red-500">
                            {errors.Twitter.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="currentRole" className=" font-[550]">
                          Website
                        </label>
                        <input
                          type="text"
                          //   placeholder="Last Name"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          defaultValue={dash.socialaccounts.WebSite}
                          name="Website"
                          {...register("WebSite", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/,
                              message: "Invalid Website URL",
                            },
                          })}
                        />
                        {errors.WebSite && (
                          <p className="text-red-500">
                            {errors.WebSite.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="firstname" className=" font-[550]">
                          GitHub
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="GitHub"
                          defaultValue={dash.socialaccounts.GitHub}
                          {...register("GitHub", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_-]+$/,
                              message: "Invalid GitHub profile link",
                            },
                          })}
                        />
                        {errors.GitHub && (
                          <p className="text-red-500">
                            {errors.GitHub.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="firstname" className=" font-[550]">
                          Instagram
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="Instagram"
                          defaultValue={dash.socialaccounts.Instagram}
                          {...register("Instagram", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+$/,
                              message: "Invalid Instagram profile link",
                            },
                          })}
                        />
                        {errors.Instagram && (
                          <p className="text-red-500">
                            {errors.Instagram.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="firstname" className=" font-[550]">
                          Discord
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="Discord"
                          defaultValue={dash.socialaccounts.Discord}
                          {...register("Discord", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?discord\.com\/[A-Za-z0-9_-]+$/,
                              message: "Invalid Discord profile link",
                            },
                          })}
                        />
                        {errors.Discord && (
                          <p className="text-red-500">
                            {errors.Discord.message}
                          </p>
                        )}
                      </div>

                      <div className="flex flex-row-reverse pt-4">
                        <button
                          type="submit"
                          onClick={() => {
                            window.scroll(0, 0);
                          }}
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        >
                          Save
                        </button>
                      </div>
                    </form> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
