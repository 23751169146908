import axios from "axios";
import React, { useState, useEffect } from "react";
import apiUrl from "../apiConfig";
import Visibilitychange from "./VisibilitychangeModal";
import Switch from "@mui/material/Switch";
import { Accordion } from "keep-react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FaSpinner } from "react-icons/fa";
import { Label, Radio } from "keep-react";

export default function ChangeVisibility({ email }) {
  const token = localStorage.getItem("token");
  const [visibility, setVisibility] = useState({ public: false });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [name, setName] = useState("public");
  const [hideName, setHideName] = useState("fullname");
  const [hideEmail, setHideEmail] = useState("none");
  const [hidePhone, setHidePhone] = useState("no-one");
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [visibilityName, setVisibilityName] = useState("");
  const [savedMessage, setSavedMessage] = useState("");
  const setCloseModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const submit = async () => {
    const reqBody = {
      visibility: {
        public: name === "public" ? !visibility.public : visibility.public,
        Recruiter:
          name === "Recruiter" ? !visibility.Recruiter : visibility.Recruiter,
        AuUser: name === "AuUser" ? !visibility.AuUser : visibility.AuUser,
      },
    };
    try {
      const resp = await axios.post(
        apiUrl.profile.editprofile + token,
        reqBody
      );
      if (resp.status === 200) {
        setCloseModal();
      }
    } catch (err) {
      // Handle error
    }
  };

  const handleVisibilityChange = () => {
    if (visibilityName == "Public") {
      setVisibilityName("Private");
    } else {
      setVisibilityName("Public");
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const resp = await axios.post(apiUrl.profile.getprofile + token);
        if (resp.status === 200) {
          setFirstName(resp.data.existing_profile.FullName.FirstName);
          setLastName(resp.data.existing_profile.FullName.LastName);
          setMiddleName(resp.data.existing_profile.FullName.MiddleName);
          setDisplayName(`${resp.data.existing_profile.FullName.DisplayName}`);
          setVisibility(resp.data.existing_profile.visibility);
          setHideName(resp.data.existing_profile.Privacy.Name);
          setHideEmail(resp.data.existing_profile.Privacy.Email);
          setHidePhone(resp.data.existing_profile.Privacy.Phone);

          if (resp.data.existing_profile.visibility.public) {
            setVisibilityName("Public");
          } else {
            setVisibilityName("Private");
          }
        }
      } catch (err) {
        // Handle error
      }
    };

    const getDetails = async () => {
      try {
        const response = await axios.post(apiUrl.getuserdetails + token);
        if (response.status == 200) {
          // // // console,log(response.data)
          setPhoneNumber(response.data.existingUser.phone);
        }
      } catch (error) {
        // // console,log(error);
      }
    };

    getDetails();
    getProfile();
  }, []);

  const handleNameChange = (hideOption) => {
    setHideName(hideOption);
    switch (hideOption) {
      case "firstname":
        setDisplayName(`${firstName[0]} ${lastName}`);
        break;
      case "lastname":
        setDisplayName(`${firstName} ${lastName[0]}`);
        break;
      case "fullname":
      default:
        setDisplayName(`${firstName} ${lastName}`);
        break;
    }
  };

  const handleSubmitForm = async () => {
    const reqbody = {
      FullName: {
        FirstName: firstName,
        LastName: lastName,
        MiddlName: middleName,
        DisplayName: displayName,
      },
      Privacy: {
        Name: hideName,
        Email: hideEmail,
        Phone: hidePhone,
      },
      visibility: {
        public:
          visibilityName === "Public" ? true : false,
        Recruiter:
          visibilityName === "Recruiter"
            ? !visibility.Recruiter
            : visibility.Recruiter,
        AuUser:
          visibilityName === "AuUser" ? !visibility.AuUser : visibility.AuUser,
      },
    };
    // // // console,log(visibilityName);
    // // // console,log(reqbody);
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      if (response.status == 200) {
        setSavedMessage("All changes saved successfully");
      }
    } catch (error) {
      // // // console,log(error);
    }
  };

  return (
    <div className="bg-white w-full rounded-xl p-4 text-black">
      <div className="text-xl font-[550]">Privacy Settings</div>
      <br />
      <Accordion
        flush={true}
        openFirstPanel={true}
        className="flex flex-col gap-2"
      >
        <Accordion.Panel className="border border-black rounded">
          <Accordion.Container>
            <Accordion.Title className="w-[95%]">
              <div className="flex justify-between">
                <div className="text-black text-base">Name</div>
                {displayName ? (
                  <div className="text-black text-base font-normal">
                    {displayName}
                  </div>
                ) : (
                  <FaSpinner
                    className="animate-spin text-[#0052ff] mr-2"
                    size={20}
                  />
                )}
              </div>
            </Accordion.Title>
            <MdKeyboardArrowRight size={20} />
          </Accordion.Container>
          <Accordion.Content>
            <form className="flex flex-col gap-1 text-black px-4 md:w-1/4 w-full">
              <div>
                <fieldset
                  className="items-center gap-2"
                  onClick={() => handleNameChange("firstname")}
                >
                  <Label
                    htmlFor="firstname"
                    className="flex text-black font-normal justify-between"
                  >
                    Hide First Name
                    <Radio
                      id="firstname"
                      name="name"
                      className="text-black"
                      checked={hideName === "firstname"}
                      onChange={() => handleNameChange("firstname")}
                    />
                  </Label>
                </fieldset>
              </div>
              <div>
                <fieldset className="items-center gap-2">
                  <Label
                    htmlFor="lastname"
                    className="flex text-black font-normal justify-between"
                  >
                    Hide Last Name
                    <Radio
                      id="lastname"
                      name="name"
                      className="text-black"
                      checked={hideName === "lastname"}
                      onChange={() => handleNameChange("lastname")}
                    />
                  </Label>
                </fieldset>
              </div>
              <div>
                <fieldset className="items-center gap-2">
                  <Label
                    htmlFor="fullname"
                    className="flex text-black font-normal justify-between"
                  >
                    Do not hide
                    <Radio
                      id="fullname"
                      name="name"
                      className="text-black"
                      checked={hideName === "fullname"}
                      onChange={() => handleNameChange("fullname")}
                    />
                  </Label>
                </fieldset>
              </div>
            </form>
          </Accordion.Content>
        </Accordion.Panel>

        <Accordion.Panel className="border border-black rounded">
          <Accordion.Container>
            <Accordion.Title className="w-[95%] ">
              <div className="flex justify-between">
                <div className="text-black text-base">Visibility</div>
                {visibilityName ? (
                  <div className="text-black text-base font-normal">
                    {visibilityName}
                  </div>
                ) : (
                  <FaSpinner
                    className="animate-spin text-[#0052ff] mr-2"
                    size={20}
                  />
                )}
              </div>
            </Accordion.Title>
            <MdKeyboardArrowRight size={20} />
          </Accordion.Container>
          <Accordion.Content>
            <div className="flex flex-col  md:w-1/4 w-full">
              <div className="flex justify-between p-2 items-center">
                <div className="flex flex-col justify-center h-full">
                  <div className="text-black font-normal">
                    Public visibility
                  </div>
                  <div className="text-sm text-gray-700">
                    Your profile will be publicly accessible
                  </div>
                </div>
                <div>
                  <Switch
                    checked={visibilityName == "Public"}
                    onClick={handleVisibilityChange}
                  />
                </div>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
      <br />
      {savedMessage != "" ? (
        <div className="flex items-center text-green-700 pt-2">
          <TiTick />
          {savedMessage}
        </div>
      ) : (
        <div className="pt-2"> </div>
      )}
      <div className="flex justify-end">
        <button
          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
          onClick={handleSubmitForm}
        >
          Save
        </button>
      </div>
    </div>
  );
}
