import { Info,Trash } from 'phosphor-react'
import { useCallback} from 'react'
import { Upload } from 'keep-react'
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../pages/Login";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";
import { IoDocumentTextSharp } from "react-icons/io5";


export default function Profile1({ formdetails, isDarkMode }) {
  const token = localStorage.getItem("token");

  const { control, handleSubmit, setValue } = useForm();
  // const [file, setFile] = useState(null); // State to store the selected file
  const navigate = useNavigate();
  // const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  
  const [files, setFiles] = useState([])

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles)
  }, [])

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setFileName(file.name);
  //     // Set the value of the 'file' field in the form
  //     // // // console,log(file);
  //     setValue("file", file);
  //   }
  // };

  useEffect(() => {
    const verifyDetails = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofile + `${token}`
        );
        // // // console,log(response.data);
        if (response.data.existing_profile.Resume != "") {
          // navigate("/");
        }
      } catch (error) {
        // // // console,log(error);
      }
    };

    verifyDetails();
  }, []);

  const handleSkip = async () => {
    try {
      seterr("")
      const reqbody = {
        ph:"",
        profilestatus: "/signup/address"
      };
      const response = await axios.put(apiUrl.profile.sendsms + token, reqbody);
      if (response.status == 200) {
        navigate(`/signup/address`);
      }
    } catch (err) {
      // toast.error("something went wrong try again");
    }
  }

  // const onFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  // };

  const [err, seterr] = useState("");
  const onFileSubmit = async () => {
    // // // console,log(files)
    if (validateFile(files[0])) { 
      seterr("invalid file format");
      return;
    }
    seterr("");
    // // // console,log("Hello");
    setLoading(true);
    try {
      const formData = { sectionName: "Resume" ,profilestatus: "/signup/address"};
      formData["file"] = files[0];
      // formData.append('file', data.file);
      // Get the current date
      const currentDate = new Date();

      // Append the current date to the form data
      formData["createdDate"] = currentDate.toISOString();

      // // // console,log(formData);

      // Send the file to the backend using Axios
      const response = await axios.post(
        `${apiUrl.profile.addresume}${token}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const result = response.data;
        formdetails.uploadedFile = result.link; // Adjust based on your backend response
        setLoading(false);
        navigate(`/signup/address`);
        window.scroll(0, 0);
      } else {
        // console.error("File upload failed");
        // Handle the error as needed
      }
    } catch (error) {
      // console.error("Error uploading file", error);
      // Handle the error as needed
    }
  };

  const validateFile = (data) => {
    if (data === undefined) {
      return true;
    }
    if (data.type === "application/pdf") {
      return false;
    }
    return true;
  };

  if (token == null) {
    return <Login />;
  } else {
    return (
      <div>
        <div className=" h-1 w-full flex">
          <div className="h-full bg-[#0052ff] w-[25%]"></div>
          <div className="h-full  w-[90%]"></div>
        </div>
        <div className="m-4 ">
          <div className="text-center py-3 text-2xl font-[550] font-sans">
            Upload a Resume/CV
          </div>
          <div>
            <form
              onSubmit={handleSubmit(onFileSubmit)}
              className="flex flex-col p-4 justify-center"
            >
              {/* <Upload
                className={`p-4 my-4 `}
                fileType="Files accepted: .pdf"
                title="Click here to upload file"
                onFileChange={handleFileChange}
                file={fileName}
              /> */}
              <Upload options={{ onDrop }}>
                <Upload.Body>
                  <Upload.Icon>
                  <IoDocumentTextSharp size={30}/>
                  </Upload.Icon>
                  <Upload.Text>
                    <p className="text-body-3 font-[550] text-metal-600">
                      Drag & Drop or Choose File to Upload
                    </p>
                    <p className="text-body-4 font-normal text-metal-400">
                      DOCX and PDF formats, up to 1 MB.
                    </p>
                  </Upload.Text>
                </Upload.Body>
                <Upload.Footer isFileExists={files.length > 0}>
                  <p className="my-2 flex items-center gap-1 text-body-4 font-normal text-metal-600">
                    <Info size={16} />
                    Uploaded Files
                  </p>
                  <ul className="space-y-1">
                    {files?.map((file) => (
                      <li
                        key={file?.name}
                        className="flex items-center justify-between border-l-4 border-l-metal-100 bg-metal-25 px-4 py-2.5 text-left text-body-4 font-normal capitalize text-metal-600"
                      >
                        {file?.name}
                        <Trash size={25} color="red" className=' hover:bg-red-200 border border-none rounded-full duration-300' onClick={()=>{setFiles([])}}/>
                      </li>
                    ))}
                  </ul>
                </Upload.Footer>
              </Upload>
              {err && (
                <p className="text-center text-red-500 ">
                  *File upload error. Please upload the file or check the file
                  type.*
                </p>
              )}
              <br />
              {/* {fileName && (
            <div className="mb-4">
            <embed
            src={URL.createObjectURL(fileName)}
            width="100%"
                height="500"
                />
            </div>
          )} */}
              {/* {!validateFile() && (
            <p className="text-red-500">Please enter a valid PDF file.</p>
          )} */}

              <div className="flex flex-row-reverse  w-full justify-between">
                {loading ? (
                  <FaSpinner
                    size={30}
                    className=" text-[#0052ff] animate-spin"
                  />
                ) : (
                  <div>
                    {/* <button
                      onClick={() => navigate("/profile/page0")}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                    <button
                      type="submit"
                      className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                    >
                      Next
                    </button>
                  </div>
                )}
                <button
                  className="text-[#0052ff]  font-[550] "
                  onClick={handleSkip}
                >
                  Skip
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
