import { logincontext } from "./Logincontext";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import apiUrl from "../apiConfig";

function Userloginstore({ children }) {
  const [currentuser, setcurrentuser] = useState({});
  const [loginerror, setloginerror] = useState("");
  const [UserloginStatus, setUserloginStatus] = useState(false);
  const [loggedInUser,setLoggedInUser] = useState({});
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  const Loginuser = async (userobj, url) => {
    // // // console,log(userobj);

    try {
      const response = await axios.post(apiUrl.login, userobj);
      // // // // console,log(response)
      // // console,log(response);
      if (response.status === 200) {
        setLoggedInUser(response.data.profile);
        setUserloginStatus(true);
        setloginerror("");
        // // // console,log(response.data);
        let token = response.data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("refreshtoken", response.data.refreshtoken);
        // // // console,log(response.data.profile.profilestatus)
        if(response.data.type == 'admin'){
          navigate(`/admin/${response.data.existing_admin.email}`)
        }
        else if(response.data.profile.profilestatus != "/"){
          navigate(response.data.profile.profilestatus)
        }
        else{
        navigate("/" + response.data.url);
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          // toast('Enter correct mail id')
          // toast.error("Enter correct mail id");
          setloginerror("Enter correct mail id / password");
        } else if (err.response.status === 401) {
          // toast('Enter correct password')
          // toast.error("Enter correct password");

          setloginerror("Enter correct mail id / password");
        } else {
          // toast('Login Error')
          // toast.error("Login error");
          setloginerror("internal server error");
        }
      } else {
        // toast.error("Unexpected Error");
      }
    }
  };

  const VerifyOTP = (userobj, writtenOtp) => {
    if (writtenOtp === otp) {
      axios
        .post(apiUrl.verifyotp, userobj)
        .then((response) => {
          //  //// // // console,log(response.message)
          if (response.status === 200) {
            //// // // console,log(response.data);
            const token = response.data.token;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshtoken", response.data.refreshtoken);
            localStorage.setItem("type", response.data.type);
            navigate(`/signup/phoneno`);
            window.scroll(0, 0);
            // toast.success("Account verified successfully!!!")
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              // toast.warn("Account with this mail id already exists");
            } else {
              // toast.error(err.response.data.message);
              setloginerror(err.response.data.message);
            }
          } else {
            // toast.error("Unexpected Error");
          }
        });
    } else {
      // toast.error("Enter Correct OTP");
    }
  };

  const VerifyForgotPasswordOTP = (userobj, writtenOtp) => {
    if (writtenOtp === otp) {
      return true;
    } else {
      return false;
    }
  };

  const Signupuser = async (userobj) => {
    // // // console,log(userobj);
    try {
      const response = await axios.post(apiUrl.usersignup, userobj);
    
      if (response.status === 200) {
        // // // console,log(response.data);
        setOtp(response.data.otp);
        // toast.success("OTP successfully sent");
        return false;
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          // toast.warn("Account with this mail id already exists");
          // setloginerror(err.response.data.message);
          // console,log(err.response.data.error);
          return err.response.data.error;
        } else {
          return err.response.data.error;
        }
      } else {
        // toast.error("Unexpected Error");
      }
    }
    
  };

  const SignupEmployer = async (user) => {
    try {
      const response = await axios.post(apiUrl.employer.signupemployeruser,user);
      if(response.status == 200){
        localStorage.setItem('token',response.data.token);
        localStorage.setItem('type','recruiter');
        navigate('/recruiter')
      }
    } catch (error) {
      // // console,log(error);
    }
  }

  const LoginEmployer = async (user) =>{
    // // console,log(user)
    try {
      const response = await axios.post(apiUrl.employer.loginemployeruser,user);
      if(response.status == 200){
        localStorage.setItem('token',response.data.token);
        localStorage.setItem('type','recruiter');
        navigate('/recruiter')
      }
    } catch (error) {
      // // console,log(error);
    }
  }

  const Signupadmin = (userobj) => {
    //// // // console,log(userobj);
    //make http post request to server new user to local api
    // axios
    //   .post(apiUrl.adminsignup, userobj)
    //   .then((response) => {
    //     //  //// // // console,log(response.message)
    //     if (response.status === 200) {
    //       setcurrentuser(response.data.admin.username);
    //       setUserloginStatus(true);
    //       setloginerror("");
    //       localStorage.setItem("token", response.data.token);
    //       const id = response.data.admin._id;
    //       localStorage.setItem("id", id);
    //       navigate(`/admindashboard/${id}`);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //       if (err.response.status === 400) {
    //         // toast.warn("Username already exists");
    //       } else {
    //         // toast.error("Login Error");
    //         setloginerror(err.response.data.message);
    //       }
    //     } else {
    //       // toast.error("Unexpected Error");
    //     }
    //   });
  };
  const Logoutuser = () => {
    // toast.success("You have succesfully logged out");
    localStorage.clear();
    setUserloginStatus(false);
    navigate("/");
  };
  return (
    <logincontext.Provider
      value={[
        currentuser,
        loginerror,
        UserloginStatus,
        Loginuser,
        Signupuser,
        VerifyOTP,
        VerifyForgotPasswordOTP,
        Signupadmin,
        Logoutuser, setloginerror,
        SignupEmployer,
        LoginEmployer
      ]}
    >
      {children}
    </logincontext.Provider>
  );
}
export default Userloginstore;
