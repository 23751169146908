import React, { useState, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import Awards from "../Awards";
import apiUrl from "../../apiConfig";
import axios from "axios";
import DefaultCompany from "../../images/DefaultCompany.png";

import { IoIosArrowUp } from "react-icons/io";
export default function AwardsComponent({
  publicview,
  isDarkMode,
  handleAwardsModal,
  dash,
  api,
  setapi,
  visibleItemsAwards,
  showallAwards,
  setshowallAwards,
}) {
  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
  }, []);

  return (
    <div
      className={`w-full border-[1px] px-4 py-3 md:pt-5 md:px-6 md:rounded-md  ${
        isDarkMode ? "bg-[#3b4354]" : "bg-white"
      }`}
    >
      <div className="text-lg font-[550] flex justify-between ">
        Awards{" "}
        {!publicview && (
          <div className="flex gap-2">
            {" "}
            <div
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
              onClick={handleAwardsModal}
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      <div>
        {visibleItemsAwards.map((item, key) => {
          const company = companies.find((obj) => obj.Name === item.Issuer);
          // Get the image link if the university is found
          const image = company ? company.s3Link : DefaultCompany;
          return (
            <Awards
              key={key}
              props={{
                token: token,
                item: item,
                image: image,
                publicview: publicview,
                isDarkMode: isDarkMode,
                api: api,
                setapi: setapi,
                length: dash.awards.length,
                key: key,
              }}
            />
          );
        })}
      </div>
      {dash.awards.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center  border-slate-300">
          {/* <hr className="w-[30%]" /> */}
          <button
            className=" border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setshowallAwards(!showallAwards)}
          >
            {showallAwards
              ? "Show Less "
              : `Show All ${dash.awards.length} `}{" "}
            <IoIosArrowUp
              className={showallAwards ? "rotate-0" : "rotate-180"}
            />
          </button>
          {/* <hr className="w-[30%]" /> */}
        </div>
      )}
    </div>
  );
}
