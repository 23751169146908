import React from 'react'
import AccessTable from '../components/AccessTable'


export default function AccessManagement({url,isDarkMode}) {
  return (
    <div className={`flex flex-col ${isDarkMode? " bg-[#282c34]":  "bg-white"} rounded-xl`}>
      <AccessTable url={url} isDarkMode={isDarkMode}/>
    </div>
  )
}
