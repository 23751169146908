import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import error from "../asserts/error.png"
import { toast } from 'react-toastify'

export default function ReDashboard({url}) {
  const navigate = useNavigate()

  
  const handleLogin = () => {
    navigate(`/`)
    if(localStorage.getItem("type") === "user"){
        navigate(`/`+ url)
    }
    if(localStorage.getItem("type") === "admin"){
      navigate(`/admindashboard`)
  }
  }
  return (
   <div className='h-[80vh] w-full flex justify-center items-center'>
    <img src={error} alt="" className='md:h-[90%] opacity-20 absolute -z-100' />
    <div className='flex flex-col h-full w-full justify-center items-center gap-6 z-0'>
      <div className='md:text-5xl text-2xl font-[550]'>You have already logged in!!</div>
      <div className='md:text-3xl text-lg text-center'> Click on the below button to redirect to Dashboard</div>
      <button className="bg-[#0052ff] hover:bg-[#014cec] text-white font-[550] py-2 px-2 rounded-xl md:w-[30%] w-[80%] my-4 md:my-0" onClick={handleLogin}>Go to Dashboard</button>
    </div>

   </div>
  )
}