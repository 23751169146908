import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Months from "../asserts/Months";
import countriesList from "../asserts/Countrylist";
import apiUrl from "../apiConfig";
import DefaultCompany from "../images/DefaultCompany.png";

export default function EditVolExpModal({
  vieweditprofile,
  setvieweditprofile,
  values,
  isDarkMode,
  api,
  setapi,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [check, setcheck] = useState(
    values.toMonth === "" && values.toYear === "" ? true : false
  );
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyImage, setSelectedCompanyImage] =
    useState(DefaultCompany);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
        const selectedObj =
          response.data.find((obj) => obj.Name == values.Organisation) ||
          response1.data.find((obj) => obj.Name == values.Organisation);
        setSelectedCompanyImage(selectedObj.s3Link);
        // setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
    setSelectedCompany(values.Organisation);
    // // // console,log(values)
  }, []);
  const submitform = async (data) => {
    // // // // console,log(data)
    data.Organisation = selectedCompany;
    data.fromMonth = selectedMonth;
    data.fromYear = selectedYear;
    if (data.isPursuing) {
      data.toMonth = "";
      data.toYear = "";
    }
    const reqbody = {
      volexperience: data,
      id: values._id,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editexp + token,
        reqbody
      );
      //// // // console,log(response);
      if (response.status == 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        closemodal();
      }
    } catch (err) {
      //// // // console,log(err);
    }
  };
  const closemodal = () => {
    setvieweditprofile(!vieweditprofile);
    setapi(!api);
  };

  const handleCompanySelect = (obj) => {
    setSelectedCompany(obj.Name);
    setSelectedCompanyImage(obj.s3Link);
    setFilteredCompanies([]);
  };

  const handleCompanyChange = (e) => {
    const inputSchoolOrCompany = e.target.value;
    setSelectedCompany(inputSchoolOrCompany);
    // Filter schools based on input
    // You would replace usSchools with the array containing your school names
    // For the purpose of this example, let's assume we have a dummy array called usSchools

    const filtered1 = companies.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchoolOrCompany.toLowerCase())
    );

    const filtered2 = companies.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchoolOrCompany.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchoolOrCompany == "") {
      setSelectedCompanyImage("");
    }

    setFilteredCompanies(finalFiltered);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCompanies.length > 0) {
        const dropdown = document.getElementById("schoolDropdown");
        if (dropdown && !dropdown.contains(event.target)) {
          setFilteredCompanies([]);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filteredCompanies]);
  const [toyears, settoyears] = useState(
    Months[1].slice(0, Months[1][0] - values.fromYear + 1)
  );
  const [selectedYear, setSelectedYear] = useState("");
  const yearselected = (e) => {
    setSelectedYear(e.target.value);
    settoyears(Months[1].slice(0, Months[1][0] - parseInt(e.target.value) + 1));
  };

  const [tomonths, settomonths] = useState(Months[0]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedtoYear, setSelectedtoYear] = useState("");
  const monthsselected = (e) => {
    setSelectedMonth(e.target.value);
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    // // // console,log(selectedYear, selectedtoYear);
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(e.target.value)));
    } else {
      settomonths(Months[0]);
    }
  };

  useEffect(() => {
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(values.fromMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);

  useEffect(() => {
    setSelectedMonth(values.fromMonth);
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    if (values.fromYear === values.toYear) {
      settomonths(Months[0].slice(Months[0].indexOf(values.fromMonth)));
    } else {
      settomonths(Months[0]);
    }
    setSelectedYear(values.fromYear);
    setSelectedtoYear(values.toYear);
  }, []);

  const [selectedCountry, setselectedCountry] = useState("");
  const [filteredCounties, setfilteredCounties] = useState([]);

  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCounties.length > 0) {
        const dropdown = document.getElementById("country");
        if (dropdown && !dropdown.contains(event.target)) {
          setfilteredCounties([]);
        }
      }
    };

    const handleEscapeKey = (event) => {
      // Close the dropdown if the "Escape" key is pressed
      if (event.key === "Escape") {
        setfilteredCounties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredCounties]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closemodal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">
                      Edit Volunteer Experience
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <br />
                  <div className="">
                    <form
                      action=""
                      onSubmit={handleSubmit(submitform)}
                      className="flex flex-col gap-4"
                    >
                      <div>
                        <label htmlFor="company" className="font-[550]">
                          Organization <span className="text-red-500">*</span>
                        </label>
                        <div
                          className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedCompanyImage && (
                            <img
                              src={selectedCompanyImage}
                              alt="Company Logo"
                              className="h-6 w-6 ml-2"
                            />
                          )}
                          <input
                            type="text"
                            className="focus:outline-none w-[99%]"
                            name="company"
                            {...register("company", {
                              maxLength: {
                                value: 100,
                                message:
                                  "Organization must not exceed 100 characters",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                                message:
                                  "Organization can only contain alphanumeric characters, spaces, underscores, and hyphens",
                              },
                            })}
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                          />
                        </div>
                        {errors.company && (
                          <p className="text-red-500 text-sm">
                            {errors.company.message}
                          </p>
                        )}
                        {/* Dropdown menu for filtered schools */}
                        {filteredCompanies.length > 0 && (
                          <div
                            id="schoolDropdown"
                            className="z-10 mt-1 bg-white border rounded-md w-[100%] relative max-h-[150px] overflow-y-auto shadow-lg"
                          >
                            {filteredCompanies.map((obj, index) => (
                              <div
                                key={index}
                                className="cursor-pointer flex hover:bg-gray-100 p-2"
                                onClick={() => handleCompanySelect(obj)}
                              >
                                <img
                                  src={obj.s3Link}
                                  alt="Company Logo"
                                  className="h-6 w-6 mr-2 rounded-sm"
                                />
                                {/* Display School logo */}
                                {obj.Name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="jobTitle" className="font-[550]">
                          Role <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="jobTitle"
                          required
                          defaultValue={values.Role}
                          {...register("Role", {
                            maxLength: {
                              value: 100,
                              message: "Role must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "Role can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                        />
                        {errors.Role && (
                          <p className="text-red-500 text-sm">
                            {errors.Role.message}
                          </p>
                        )}
                      </div>

                      <div>
                        <label className="font-[550] block">
                          Time period <span className="text-red-500">*</span>
                        </label>
                        <div className="py-3 hover:cursor-pointer">
                          <input
                            type="checkbox"
                            name="pursuing"
                            id="pursuing"
                            defaultChecked={
                              values.toMonth === "" && values.toYear === ""
                                ? true
                                : false
                            }
                            {...register("isPursuing")}
                            onChange={() => {
                              setcheck(!check);
                            }}
                          />
                          <label
                            htmlFor="pursuing"
                            className="text-sm text-justify hover:cursor-pointer px-2"
                          >
                            Currently employed here
                          </label>
                        </div>
                        <div className="py-3">
                          <label
                            htmlFor="fromMonth"
                            className="font-[550] block"
                          >
                            From <span className="text-red-500">*</span>
                          </label>
                          <div className="flex justify-between">
                            <select
                              className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                isDarkMode
                                  ? "border-[#3b4354] bg-[#3b4354] "
                                  : "bg-white text-black"
                              }`}
                              name="fromMonth"
                              required
                              {...register("fromMonth")}
                              value={selectedMonth}
                              onChange={monthsselected}
                            >
                              <option value="">Month</option>
                              {Months[0].map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                            <select
                              className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                isDarkMode
                                  ? "border-[#3b4354] bg-[#3b4354] "
                                  : ""
                              }`}
                              name="Month"
                              required
                              {...register("fromYear")}
                              value={selectedYear}
                              onChange={yearselected}
                            >
                              <option value="">Year</option>
                              {Months[1].map((country, index) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {!check || check === "false" ? (
                          <div>
                            <label
                              htmlFor="toMonth"
                              className="font-[550] block"
                            >
                              To <span className="text-red-500">*</span>
                            </label>
                            <div className="flex justify-between">
                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="toMonth"
                                required
                                defaultValue={values.toMonth}
                                {...register("toMonth")}
                              >
                                <option value="">Month</option>
                                {tomonths.map((month, index) => (
                                  <option key={index} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="toYear"
                                required
                                defaultValue={values.toYear}
                                {...register("toYear")}
                                onChange={(e) =>
                                  setSelectedtoYear(e.target.value)
                                }
                              >
                                <option value="">Year</option>
                                {toyears.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label htmlFor="description" className="font-[550]">
                          Description
                        </label>
                        <div className="quill-container">
                          <textarea
                            className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                              isDarkMode
                                ? "border-[#3b4354] bg-[#3b4354] "
                                : "bg-white text-black"
                            }`}
                            name="description"
                            rows="4"
                            defaultValue={values.description}
                            {...register("description")}
                          ></textarea>
                        </div>
                      </div>
                      <div className="flex flex-row-reverse justify-between">
                        <button
                          type="submit"
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
