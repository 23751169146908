import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";

export default function DarkMode({ isDarkMode, toggleDarkMode }) {
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(
    isDarkMode ? "Dark Mode" : "Light Mode"
  );

  const label = ["Dark Mode", "Light Mode"];

  const handleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <div className={` ${
      isDarkMode ? "bg-[#282c34]" : "bg-white"
    } w-full  rounded-xl p-4`}>
      
      <h1 className="text-xl font-[550]">Dark Mode</h1>
      <div className="py-2 text-sm">
        Choose how your Aspireup experience looks for this device.
      </div>
      <div className="flex flex-col gap-2 pt-2">
        {label.map((item, key) => (
          <div key={key}>
            <input
              type="radio"
              name="role"
              id={item}
              value={item}
              onChange={handleChange}
              className="mx-2"
              checked={selectedRole === item}
            />
            <label htmlFor={item}>{item}</label>
          </div>
        ))}
      </div>
      <div className="py-2 flex justify-end">
        {loading ? (
          <FaSpinner size={30} className="animate-spin text-[#0052ff] mr-2" />
        ) : (
          <button
            type="submit"
            onClick={() => toggleDarkMode(selectedRole === "Dark Mode")}
            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
