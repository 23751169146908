import React from "react";
import Tags from "./Tags";

export default function OuterTag({ refresh, setrefresh, currentUserId }) {
  window.scrollTo(0, 0);
  return (
    <div className="mb-2">
      <Tags
        refresh={refresh}
        setrefresh={setrefresh}
        currentUserId={currentUserId}
      />
    </div>
  );
}
