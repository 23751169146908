import React from "react";
import { LuPencilLine } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { useState } from "react";
import axios from "axios";
import EditExpModal2 from "./EditExpModal2";
import apiUrl from "../apiConfig";
import DeleteModal from "./DeleteModal";
import EditVolExpModal from "./EditVolExpModal";
import DashSecOptions from "./DashSecOptions";

export default function VolExp({ props }) {
 
  const [showModal, setShowModal] = useState(false);
  const [showexpmodal, setshowexpmodal] = useState(false);
  const [desc, setdesc] = useState(false);

  const onClick = () => {
    setShowModal(!showModal);
    props.setapi(!props.api);
  };

  const compdesc = (role) => {
    if (desc) {
      return role.description;
    } else {
      return role.description.slice(0, 200);
    }
  };

  const handleDeleteExperience = async () => {
    try {
      const response = await axios.post(
        apiUrl.profile.deleteprofile + props.token,
        {
          volexperience: props.item.roles[rolenumber],
        }
      );
      // // // console,log(response);
      if (response.status == 200) {
        // alert("Deleted Successfully");
        setShowModal(!showModal);
        props.setapi(!props.api);
        // Reload the page after successful deletion
        // window.location.reload();
      } else {
        // // // console,log(response.message);
      }
    } catch (error) {
      // // // console,log(error.response.body);
    }
  };

  const [exp, setexp] = useState({});
  const handleEdit = (number) => {
    setrolenumber(number);
    // // // console,log(props.item);
    const exp1 = {
      Organisation: props.item.Organisation,
      ...props.item.roles[number],
    };
    // // // console,log(exp1);
    setexp(exp1);
    setshowexpmodal(!showexpmodal);
    props.setapi(!props.api);
  };

  const [rolenumber, setrolenumber] = useState(0);

  const getMonthNumber = (monthName) => {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };

    return months[monthName] || "Invalid month";
  };
  const gettotalmonthsyears = (month1, year1, month2, year2) => {
    // Parse the start and end dates, handling invalid input gracefully
    // console.log(month1, year1, month2, year2);
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });
    const currentYear = currentDate.getFullYear();

    const startMonth = month1 || currentMonth;
    const startYear = year1 || currentYear;
    const startDate = new Date(startYear, getMonthNumber(startMonth) - 1, 1);
    const endDate = new Date(year2, getMonthNumber(month2) - 1, 1);
    // console.log(startDate, endDate);
    // Ensure valid dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return "Invalid date input";
    }

    // Calculate the difference in years and months
    let years = startDate.getFullYear() - endDate.getFullYear();
    let months = startDate.getMonth() - endDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    // Return the difference as a string
    if (years === 0) {
      return `${months} ${months === 1 ? "month" : "months"}`;
    }
    if (months === 0) {
      return `${years} ${years === 1 ? "year" : "years"}`;
    }
    return `${years} ${years === 1 ? "year" : "years"} and ${months} ${
      months === 1 ? "month" : "months"
    }`;
  };

  return (
    <div className="pt-2">
      {showexpmodal && (
        <EditVolExpModal
          vieweditprofile={showexpmodal}
          setvieweditprofile={setshowexpmodal}
          values={exp}
          isDarkMode={props.isDarkMode}
          api={props.api}
          setapi={props.setapi}
        />
      )}
      {showModal && (
        <DeleteModal
          onClick={onClick}
          handleDeleteEducation={handleDeleteExperience}
          isDarkMode={props.isDarkMode}
          inst={props.item.roles[rolenumber].Role}
          name={"volunteer experience"}
        />
      )}
      <div className="flex justify-between w-[95%] ">
        <div className="flex gap-2 flex-col  w-full ">
          <div className=" flex gap-2 items-center">
            <div className="md:h-[50px] h-[40px] aspect-square">
              {props.image && (
                <img
                  src={props.image}
                  alt="Company Logo"
                  className=" rounded-md"
                />
              )}
            </div>
            <div className=" flex flex-col  justify-center h-full ">
              <p className="font-[550]">{props.item.Organisation} </p>
              <p className="text-sm text-gray-400">
                {gettotalmonthsyears(
                  props.item.roles[0].toMonth,
                  props.item.roles[0].toYear,
                  props.item.roles[props.item.roles.length - 1].fromMonth,
                  props.item.roles[props.item.roles.length - 1].fromYear
                )}
              </p>
            </div>
          </div>
          <div className="text-md w-full relative flex gap-2 ">
            <div className="w-[40px] md:w-[50px] relative">
              <div className=" absolute h-full border-[1px]  left-1/2 "></div>
            </div>
            <div className="flex flex-col gap-0 w-full">
              <div className=" flex flex-col gap-4 w-full ">
                {props.item.roles.map((role, key) => (
                  <div
                    key={key}
                    className=" flex justify-between items-start w-full relative"
                  >
                    {key === props.item.roles.length - 1 && (
                      <div
                        style={{ height: `calc(100% - 0.725rem)` }}
                        className="absolute border-[5px] border-white top-[0.725rem]  left-[-27px] md:left-[-32px] "
                      ></div>
                    )}
                    <div className="flex flex-col gap-0  ">
                      <div className=" flex relative">
                        <div className="absolute left-[-25px] top-1/2 md:left-[-30px] border-[1px]  w-[20px] md:w-[25px]  " />
                        <h1 className="text-sm font-[550] px-1 ">
                          {role.Role}
                        </h1>
                      </div>
                      <p className="text-sm text-gray-400 px-1">
                        {role.fromMonth} {role.fromYear} -{" "}
                        {!role.toMonth ? (
                          <span>Present</span>
                        ) : (
                          <span>
                            {role.toMonth} {role.toYear}
                          </span>
                        )}
                      </p>
                      <p className="text-sm w-full  px-1">
                        {compdesc(role)}
                        {role.description.length >= 200 && (
                          <button
                            onClick={() => setdesc(!desc)}
                            className="text-gray-400"
                          >
                            {desc ? " ...show less" : " ...show more"}
                          </button>
                        )}{" "}
                      </p>
                    </div>
                    {!props.publicview && (
                      <div className="">
                        <DashSecOptions
                          edititem={() => handleEdit(key)}
                          deleteitem={() => {
                            // // console,log(key, props.item.roles[key].school);
                            setrolenumber(key);
                            setShowModal(!showModal);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='w-full flex justify-center my-4'>

  <div className='h-[2px] bg-slate-200 w-[95%]'></div>
  </div> */}

      {props.length - 1 !== props.key && (
        <hr className=" text-center w-[95%] mt-2" />
      )}
    </div>
  );
}
