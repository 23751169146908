import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { logincontext } from "../contexts/Logincontext";
import { useForm } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import ChangePassword from "../images/Change Password.webp";

// import { toast } from "react-toastify";
import axios from "axios";
import { PopoverComponent } from "./Popover";
import apiUrl from "../apiConfig";

export default function ForgotPasswordForm({ isDarkMode, title, email, url }) {
  const [
    currentuser,
    loginerror,
    UserloginStatus,
    Loginuser,
    Signupuser,
    VerifyOTP,
    VerifyForgotPasswordOTP,
    Signupadmin,
    Logoutuser,
  ] = useContext(logincontext);
  const [viewmailotp, setviewotp] = useState(false);
  const [viewnewpassword, setviewnewpassword] = useState(false);
  const [captcha, setcaptcha] = useState(undefined);
  const [data, setdata] = useState();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [validLength, setValidLength] = useState(false);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [showrules, setshowrules] = useState(false);
  const handleChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidLength(newPassword.length >= 8);
    setContainsUpperCase(/[A-Z]/.test(newPassword));
    setContainsLowerCase(/[a-z]/.test(newPassword));
    setContainsSpecialChar(
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)
    );
    setContainsNumber(/\d/.test(newPassword));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [timer, setTimer] = useState(60);
  const [disabled, setDisabled] = useState(true);
  const [savedMessage, setSavedMessage] = useState("");

  const startTimer = () => {
    setDisabled(true);
    setTimer(60);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setDisabled(false);
    }, 60000);
  };
  // useEffect(() => {
  //   startTimer(); // Start the timer when the component mounts
  // }, []);
  useEffect(() => {
    if (timer === 0) {
      setDisabled(false);
    }
  }, [timer]);

  const handleResendClick = async () => {
    const resdata = await ForgotPassword(data);
    startTimer();
  };
  const [showpassword, setshowpassword] = useState("password");
  const showpass = () => {
    const type = showpassword === "password" ? "text" : "password";
    setshowpassword(type);
  };

  const [loading, setLoading] = useState(false); // State to track loading

  const ForgotPassword = async (userobj) => {
    // // // // console,log(userobj);

    // request otp
    try {
      const response = await axios.post(
        apiUrl.forgotpassword.sendcode,
        userobj
      );

      if (response.status === 200) {
        setcaptcha(response.data.captcha);
        seterr("");
        return response.data;
      }
    } catch (err) {
      seterr(err.response.data.message);
    }
  };

  const token = localStorage.getItem("token");
  const [err, seterr] = useState("");
  const submitform = async (data) => {
    if (email) data["email"] = email;
    // // // console,log(data)
    setLoading(true);
    data["password"] = password;
    // // // console,log("data: ", data);
    setdata(data);
    const keys = Object.keys(data);
    seterr("");
    // // // console,log(keys.length);
    if (keys.length < 4) {
      if (viewmailotp) {
        if (captcha == data.captcha) {
          setviewnewpassword(!viewnewpassword);
          seterr("");
          // toast.success("Captcha verified");
          setviewotp(!viewmailotp);
        } else {
          seterr("Incorrect Captcha. please check again");
          // toast.warn("Enter Correct Captcha");
        }
      }
      if (!viewmailotp) {
        const resdata = await ForgotPassword(data);
        if (resdata) {
          startTimer();
          setviewotp(!viewmailotp);
        }
      }
    } else {
      if (password != data.cnewpassword) {
        // toast.warn("Passwords not matching");
        seterr("Passwords not matching");
      } else {
        seterr("");
        try {
          const userdata = {
            email: data.email,
            newpassword: password,
          };
          const response = token
            ? await axios.post(
                apiUrl.forgotpassword.changepassword + token,
                userdata
              )
            : await axios.post(apiUrl.forgotpassword.forgotpassword, userdata);
          // // // console,log(response);
          if (response.status === 200) {
            // toast.success("Password Successfully Changed");

            if (!token) navigate("/login");
            else {
              // navigate("/" + url);
              setSavedMessage("Password changed successfully");
            }
          }
        } catch (err) {
          //  seterr(err.response)
          // // console,log("error");
        }
      }
    }
    setLoading(false);
  };

  const [first, setfirst] = useState(true);

  return (
    <div className="">
      <h1
        className={`${
          title === "Change Password" ? "text-left" : "text-center"
        }  pt-3 text-2xl font-[550] font-sans`}
      >
        {title}
      </h1>
      <h4
        className={`py-2 text-sm  ${
          title === "Change Password" ? "text-left" : "text-center"
        } `}
      >
        We’ll send a verification code to this email
      </h4>

      <form onSubmit={handleSubmit(submitform)} className="p-4">
        {err && <p className="text-center text-red-500 font-[550]">{err}</p>}
        <input
          type="email"
          defaultValue={email ? email : ""}
          disabled={email && !(!viewmailotp && viewnewpassword)}
          placeholder="Email"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
          }`}
          name="Email"
          required
          {...register("email")}
        />
        {viewmailotp && (
          <input
            type="text"
            placeholder="Enter Captcha Here"
            className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
              isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
            }`}
            name="Captcha"
            required
            {...register("captcha")}
          />
        )}
        {viewnewpassword && (
          <div>
            <input
              type={showpassword}
              value={password}
              onChange={handleChange}
              placeholder="Password"
              className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
                isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : " "
              }`}
              name="Password"
              required
              onFocus={() => setshowrules(true)}
              onBlur={() => setshowrules(false)}
            />
            {showrules && (
              <ul className="text-sm px-4 ">
                <li className={validLength ? "hidden" : "text-red-500"}>
                  At least 8 characters
                </li>
                <li className={containsUpperCase ? "hidden" : "text-red-500"}>
                  At least one uppercase letter
                </li>
                <li className={containsLowerCase ? "hidden" : "text-red-500"}>
                  At least one lowercase letter
                </li>
                <li className={containsSpecialChar ? "hidden" : "text-red-500"}>
                  At least one Special Character
                </li>
                <li className={containsNumber ? "hidden" : "text-red-500"}>
                  At least one number
                </li>
              </ul>
            )}
            <input
              type={showpassword}
              placeholder="Confirm Password"
              className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
                isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
              }`}
              name="cnewpassword"
              required
              {...register("cnewpassword")}
            />
            <div className="py-2">
              <input
                type="checkbox"
                name="show"
                id="show"
                onChange={showpass}
              />
              <label
                htmlFor="show"
                className="text-sm text-justify hover:cursor-pointer  px-2"
              >
                {" "}
                Show password
              </label>
            </div>
          </div>
        )}
        <div className="mt-2 w-full flex flex-row-reverse justify-between">
          {/* Render spinner if loading, otherwise render login button */}
          {loading ? (
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
          ) : (
            <button
              type="submit"
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
            >
              {viewnewpassword
                ? "Confirm"
                : viewmailotp
                ? "Verify Captcha"
                : "Send Verification Email"}
            </button>
          )}
          {!viewnewpassword && viewmailotp && (
            <div className="w-[60%]">
              Not received your code?{" "}
              <button
                className={`font-[550]  ${
                  disabled ? "text-gray-500" : "text-[#0052ff]"
                }`}
                onClick={handleResendClick}
                disabled={disabled}
              >
                Resend code {disabled ? `(${timer}s)` : ""}
              </button>
            </div>
          )}
        </div>
      </form>
      {savedMessage != "" ? (
        <div className="flex items-center text-green-700 pt-2">
          <TiTick />
          {savedMessage}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
