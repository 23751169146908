import React from "react";
import {  Outlet } from "react-router-dom";
export default function Profileincom({isDarkMode}) {
  
  return (
    <div className="flex justify-center items-center p-3 min-h-[90vh] w-full">
       <div className="max-w-screen-xl flex justify-center w-full items-center ">
          <div  className={`flex flex-col w-[100vw] lg:w-[40%] h-full  border-2 rounded-lg shadow-lg ${
              isDarkMode
                ? "shadow-[#3b4354] border-[#3b4354]"
                : "shadow-slate-300 "
            } `}
          > 
           
        <Outlet/>
      </div>
      </div>
    </div>
  );
}
