import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../apiConfig";

export default function EditSummaryModal({
  manageSummarymodal,
  isDarkMode,
  dash,
  api,
  setapi,
}) {
  const [open, setOpen] = useState(true);
  const { register, handleSubmit, formState: err } = useForm();
  const token = localStorage.getItem("token");
  const submitform = async (data) => {
    const dat = {
      summary: data.summary,
    };
    try {
      const resp = await axios.post(apiUrl.profile.editprofile + token, dat);
      // // // console,log(resp);
      if (resp.status === 200) {
        setapi(!api);
        manageSummarymodal();
      }
    } catch (error) {}
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={manageSummarymodal}>
             <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                      isDarkMode
                        ? "bg-[#2a303c] border-[#2a303c]"
                        : "bg-white text-black"
                    }`}
                  >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">Edit Summary</div>
                    <IoMdClose
                      size={30}
                      onClick={manageSummarymodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  {/* <div className=" text-sm text-gray-700">
                    You can write about your years of experience, industry, or
                    skills. People also talk about their achievements or
                    previous job experiences.
                  </div> */}
                  <div>
                    <form onSubmit={handleSubmit(submitform)}>
                      <textarea
                        name="summary"
                        id="summary"
                        className={`w-full p-2    border-[1.5px] focus:outline-none rounded-md my-2  ${
                          isDarkMode
                            ? "border-[#3b4354] bg-[#3b4354] "
                            : "bg-white text-black"
                        }`}
                        rows="7"
                        defaultValue={dash.summary}
                        {...register("summary")}
                      ></textarea>
                      <div className="flex flex-row-reverse justify-between">
                        <button
                          type="submit"
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
