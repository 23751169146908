import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
const firebaseConfig = {
    apiKey: process.env.REACT_APP_Firebase_ApiKey,
    authDomain: "aspireup-otp.firebaseapp.com",
    projectId: "aspireup-otp",
    storageBucket: "aspireup-otp.appspot.com",
    messagingSenderId: "266332293928",
    appId: "1:266332293928:web:0f94871ae5ed30adde5e1d"
  };
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);