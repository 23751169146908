import React, { useState } from "react";
import skills from "../../asserts/Skills";
import { GrAdd } from "react-icons/gr";
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../pages/Login"
import { ImBin } from "react-icons/im";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";

export default function Profile6({ formdetails, isDarkMode, url }) {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const submitbut = async () => {
    // formdetails.skills = skil;
    // //// // console,log(formdetails);
    setLoading(!loading);
    const reqbody = {
      skills: skil,
      profilestatus: "/signup/add-socialprofiles",
    };

    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      //// // console,log(response)
      if (response.status == 200) {
        //// // console,log(response.body);
        // Navigate to the next page or wherever you want
        navigate(`/signup/add-socialprofiles`);
      }
    } catch (err) {
      //// // console,log(err);
    }
    setLoading(!loading);
    window.scroll(0, 0);
  };
  const [iniskills, setiniskills] = useState(skills);
  const [skil, setskills] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const addlist = (value) => {
    if (!skil.includes(value)) setskills([...skil, value]);
    setiniskills(iniskills.filter((ite) => ite !== value));
  };

  const delitem = (itemToDelete) => {
    const updatedSkills = skil.filter((item) => item !== itemToDelete);
    setskills(updatedSkills);
    setiniskills([...iniskills, itemToDelete]);
  };
  const [err, seterr] = useState("");
  const handleNewSkillChange = (e) => {
    // console.log(e.target.value);
    const isValidInput = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/.test(
      e.target.value
    );
    // console.log(isValidInput);
    if (!isValidInput) {
      // handle invalid input
      seterr(
        "link can only contain alphanumeric characters, spaces, underscores, and hyphens"
      );
    } else {
      seterr("");
    }
    setNewSkill(e.target.value);
  };

  const handleAddNewSkill = () => {
    if (newSkill.trim() !== "" && !skil.includes(newSkill)) {
      setskills([...skil, newSkill]);
      setNewSkill("");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleAddNewSkill();
  };

  if (token == null) {
    return <Login />
  } else {
    return (
      <div>
        <div className="h-1 w-full flex">
          <div className="h-full bg-[#0052ff] w-[87.5%]"></div>
          <div className="h-full "></div>
        </div>
        <div className="m-4  p-2">
          <div className="py-3 text-2xl font-[550] font-sans">
            What are some of your skills?
          </div>
          <div className="text-sm">We recommend at least 4 skills</div>
          <br />
          <div
            className={`w-full h-[40vh] border-[1.5px] rounded-2xl my-2 overflow-y-auto ${
              isDarkMode ? "border-[#3b4354]" : ""
            }`}
          >
            <div className=" flex justify-center mt-2">
              <form onSubmit={handleFormSubmit} className="">
                <input
                  type="text"
                  className={`p-2   border-[1.5px]  rounded-md my-2  ${
                    isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                  }`}
                  placeholder="Type a new skill"
                  value={newSkill}
                  onChange={handleNewSkillChange}
                />
                <button
                  className=" duration-300   font-[550] py-1 px-4 rounded-xl"
                  type="submit"
                >
                  Add
                </button>
              </form>
            </div>
            {err && <p className="text-red-500 text-sm text-center">{err}</p>}
            <ul className="flex flex-wrap p-4">
              {skil.map((item, key) => (
                <li
                  className="text-black flex items-center text-xs md:text-sm duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-black hover:bg-[#80808025] m-1"
                  key={key}
                  onClick={() => delitem(item)}
                >
                  <ImBin />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <br />
          <hr />
          <br />
          <div className="w-full justify-center flex items-center gap-2"></div>
          <br />
          <ul className="w-full flex flex-wrap gap-2">
            {iniskills.map((item, key) => (
              <li
                onClick={() => addlist(item)}
                className="text-black  text-xs md:text-sm duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-black hover:bg-[#80808025] md:my-0"
                key={key}
              >
                {item}
              </li>
            ))}
          </ul>
          <br />
          <div className="flex flex-row-reverse justify-between">
            {loading ? (
              <FaSpinner size={30} className="animate-spin text-[#0052ff]" />
            ) : (
              <div>
                {/* <button
                  onClick={() => {
                    navigate("/profile/page5");
                    window.scroll(0, 0);
                  }}
                  className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                >
                  Back
                </button> */}
               <button
            className={`  font-[550] py-1 px-4 rounded-3xl my-4 md:my-0 ${skil.length !== 0?" bg-[#0052ff] text-white   hover:bg-[#014cec]": "bg-gray-600 text-white   hover:bg-gray-500 cursor-not-allowed" }`}
            onClick={skil.length !== 0? submitbut: null}
          >
            Next
          </button>
              </div>
            )}
            {/* <button
              className="text-[#0052ff] font-[550]  flex justify-center items-center gap-1"
              onClick={() => {
                navigate(`/signup/add-socialprofiles`);
                window.scroll(0, 0);
              }}
            >
              Skip
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}
