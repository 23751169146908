import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableComponent from "./DashBoardComponents/DraggableComponent";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import apiUrl from "../apiConfig";
import { useNavigate } from "react-router-dom";
export default function ComponentOrder({
  url,
  componentOrder,
  setComponentOrder,
  comp,
  setComp,
  handleArrangeClick,
  isDarkMode, 
  manageCompModal
}) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleDrop = (draggedId, targetId) => {
    const draggedIndex = comp.indexOf(draggedId);
    const targetIndex = comp.indexOf(targetId);
    const newOrder = [...comp];
    newOrder[draggedIndex] = targetId;
    newOrder[targetIndex] = draggedId;
    setComp(newOrder);
    setComponentOrder(
      newOrder.reduce((acc, curr) => {
        acc[curr] = componentOrder[curr];
        return acc;
      }, {})
    );
  };

  const handleArrowClick = (direction, componentId) => {
    const selectedIndex = comp.indexOf(componentId);
    if (direction === "up" && selectedIndex > 0) {
      const newOrder = [...comp];
      const temp = newOrder[selectedIndex];
      newOrder[selectedIndex] = newOrder[selectedIndex - 1];
      newOrder[selectedIndex - 1] = temp;
      setComp(newOrder);
      setComponentOrder(
        newOrder.reduce((acc, curr) => {
          acc[curr] = componentOrder[curr];
          return acc;
        }, {})
      );
    } else if (direction === "down" && selectedIndex < comp.length - 1) {
      const newOrder = [...comp];
      const temp = newOrder[selectedIndex];
      newOrder[selectedIndex] = newOrder[selectedIndex + 1];
      newOrder[selectedIndex + 1] = temp;
      setComp(newOrder);
      setComponentOrder(
        newOrder.reduce((acc, curr) => {
          acc[curr] = componentOrder[curr];
          return acc;
        }, {})
      );
    }
  };


  return (
    <div className="w-full">
      <div className={` ${
            isDarkMode ? "bg-[#282c34]" : "bg-white"
          } w-[100%]  rounded-xl`}>
   
          {/* <div className="font-[550] md:text-xl">
            Customise the order to Sections
          </div>
        */}
        {comp.length > 0 ? (
          <div className="text-md py-2">
            Click on the arrows to arrange.
          </div>
        ) : (
          <div className="text-md py-2 text-center text-gray-700">
            Add sections to arrange.
          </div>
        )}
       
        {/* <div className="text-md py-2">
          Click on the arrows to arrange.
        </div> */}
        <div>
          <DndProvider backend={HTML5Backend}>
            <div>
              {comp.map((componentId) => (
               
               ( 
                  (componentId !== "Recognitions" && componentId !== "Summary") &&
                <DraggableComponent
                  key={componentId}
                  id={componentId}
                  onDrop={(draggedId, targetId) => {
                    handleDrop(draggedId, targetId);
                  }}
                >
                 
                   <div
          className={` w-full  font-[550] capitalize px-4 py-2 flex justify-between border-2 rounded-lg my-2 hover:bg-gray-100 cursor-move hover:text-black hover:border-black`}
        >
          {componentId === "volexperience"? "Volunteering": componentId} {updown(handleArrowClick, componentId)}
        </div>

                </DraggableComponent>
                )
              ))}
            </div>
          </DndProvider>
        </div>
        <div className="w-full flex  justify-end">
          <button
            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
            onClick={handleArrangeClick}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const updown = (handleArrowClick, componentId) => {
  return (
    <div className=" flex justify-end gap-4 ">
      <button onClick={() => handleArrowClick("up", componentId)}>
        <IoIosArrowUp />
      </button>
      <button onClick={() => handleArrowClick("down", componentId)}>
        <IoIosArrowDown />
      </button>
    </div>
  );
};