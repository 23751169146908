import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Upload } from "keep-react";
import { Fragment, useRef, useState } from "react";
import { Info, Trash } from "phosphor-react";
import { useCallback } from "react";
import { IoDocumentTextSharp } from "react-icons/io5";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import apiUrl from "../apiConfig";

import { FaSpinner } from "react-icons/fa";

export default function AddDocumentModal({
  handleModal,
  sectionKey,
  sectionIndex,
  isDarkMode,
  documentsSize,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [errormessage, setErrorMessage] = useState("");

  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const nameInputRef = useRef(null); // Ref for the name input field

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm();
  // const [file, setFile] = useState(null); // State to store the selected file
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // console,log(file);
    if (file) {
      setFileName(file.name);
      // Set the value of the 'file' field in the form
      setValue("file", file);
    }
  };

  const [lengthexceeded, setlengthexceeded] = useState(false);
  const handleTextChange = (event) => {
    const text = event.target.value;
    if (text.length > 20) {
      setlengthexceeded(true);
      return;
    } else {
      setlengthexceeded(false);
    }
    setName(text);
    setValue("name", text);
  };

  // const onFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  // };

  const [err, seterr] = useState("");
  const onFileSubmit = async (data) => {
    if (!files[0]) {
      setErrorMessage("Please select a file to upload");
      return;
    }
    setLoading((loading) => !loading);
    if (files[0].size + documentsSize > 1048576) {
      setErrorMessage(
        "Limit Exceeded! The total size of all documents must be less than 10 MB."
      );
      setLoading(false);
      return;
    }
    if (validateFile(files[0])) {
      seterr("invalid file format");
      setLoading(false);
      return;
    }
    //   // // // console,log("Hello");
    //   // // // console,log(fileName);
    //   // // // console,log(data.file;
    try {
      const doc = files[0];
      if (doc.name.length > 20) {
        setErrorMessage(
          <p>
            Document name should be less than 20 characters. <br /> Please
            rename the document or give a custom name.
          </p>
        );
        setLoading(false);
        return;
      }
      const formData = { sectionName: sectionKey };

      // // // console,log(formData);
      formData["file"] = files[0];
      formData["name"] = data.name;
      // formData.append('file', data.file);
      // Get the current date
      const currentDate = new Date();

      // Append the current date to the form data
      formData["createdDate"] = currentDate.toISOString();

      // // // console,log(formData);
      // Send the file to the backend using Axios
      const response = await axios.post(
        `${apiUrl.profile.addresume}${token}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setLoading(!loading);
        handleModal(sectionIndex);
      } else if (response.status === 404) {
      } else {
        console.error("File upload failed");
        // Handle the error as needed
      }
    } catch (error) {
      if (error.response.status === 413) {
        setErrorMessage("File size too large, compress it before uploading");
        setLoading(false);
        return;
      }
      setErrorMessage("File with the same name already exists");
      setLoading(false);
      console.error("Error uploading file", error);
      // Handle the error as needed
    }
  };

  const validateFile = (data) => {
    if (data === undefined) {
      return true;
    }
    if (data.type === "application/pdf") {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleModal(sectionKey)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                      isDarkMode
                        ? "bg-[#2a303c] border-[#2a303c]"
                        : "bg-white text-black"
                    }`}
                  >
                    <div className="flex w-full items-end justify-between py-2 cursor-pointer">
                      <div className="text-2xl font-[550]">Upload Document</div>
                      <IoMdClose
                        size={30}
                        onClick={() => handleModal(sectionIndex)}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      />
                    </div>
                    <div className="text pb-2">
                      {/* <p>
                        Upload a document from your device to this section for
                        easy access and organization. Ensure the document aligns
                        with the content of this section to maintain clarity and
                        coherence.
                      </p> */}
                    </div>
                    <div>
                      <div className="relative">
                        <div>
                          <form
                            onSubmit={handleSubmit(onFileSubmit)}
                            className="flex flex-col justify-center"
                          >
                            <label className="font-[550]">Name:</label>
                            {/* <label htmlFor="name"  className="font-[550]">Name: </label> */}
                            <input
                              type="text"
                              // placeholder="Name of the Document"
                              className="w-full p-2 bg-white text-black focus:outline-none  border-[1.5px]  rounded-md my-2"
                              {...register("name", {
                                maxLength: {
                                  value: 100,
                                  message:
                                    "Document name must not exceed 100 characters",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                                  message:
                                    "Document name can only contain alphanumeric characters, spaces, underscores, and hyphens",
                                },
                              })}
                              name={name}
                              onChange={handleTextChange}
                            />
                            {errors.name && (
                              <p className="text-red-500 text-sm">
                                {errors.name.message}
                              </p>
                            )}
                            {/* <br /> */}
                            {/* <Upload
                              className={`bg-transparent`}
                              fileType="Files accepted: .pdf"
                              title="Click here to upload file"
                              id="upload"
                              onFileChange={handleFileChange}
                              file={fileName}
                            /> */}
                            <div className=" flex flex-col justify-center items-center">
                              <Upload options={{ onDrop }} className="w-full">
                                <Upload.Body>
                                  <Upload.Icon>
                                    <IoDocumentTextSharp size={30} />
                                  </Upload.Icon>
                                  <Upload.Text>
                                    <p className="text-body-3 font-[550] text-metal-600">
                                      Drag & Drop or Choose File to Upload
                                    </p>
                                    <p className="text-body-4 font-normal text-metal-400">
                                      DOCX and PDF formats, up to 1 MB.
                                    </p>
                                  </Upload.Text>
                                </Upload.Body>
                                <Upload.Footer isFileExists={files.length > 0}>
                                  <p className="my-2 flex items-center gap-1 text-body-4 font-normal text-metal-600">
                                    <Info size={16} />
                                    Uploaded Files
                                  </p>
                                  <ul className="space-y-1">
                                    {files?.map((file) => (
                                      <li
                                        key={file?.name}
                                        className="flex items-center justify-between border-l-4 border-l-metal-100 bg-metal-25 px-4 py-2.5 text-left text-body-4 font-normal capitalize text-metal-600"
                                      >
                                        {file?.name}
                                        <Trash
                                          size={25}
                                          color="red"
                                          className=" hover:bg-red-200 border border-none rounded-full duration-300"
                                          onClick={() => {
                                            setFiles([]);
                                          }}
                                        />
                                      </li>
                                    ))}
                                  </ul>
                                </Upload.Footer>
                              </Upload>
                              {err && (
                                <p className="text-center text-red-500  pt-4">
                                  *File upload error. Please upload the file or
                                  check the file type.*
                                </p>
                              )}
                              <div className="text-red-500 text-center pt-4">
                                {errormessage}
                              </div>
                            </div>

                            <div className="flex pt-2 flex-row-reverse">
                              {loading ? (
                                <FaSpinner
                                  size={30}
                                  className="animate-spin text-[#0052ff] mr-2"
                                />
                              ) : (
                                <button
                                  type="submit"
                                  className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    {/* {fileName && (
            <div className="mb-4">
            <embed
            src={URL.createObjectURL(fileName)}
            width="100%"
                height="500"
                />
            </div>
          )} */}
                    {/* {!validateFile() && (
            <p className="text-red-500">Please enter a valid PDF file.</p>
          )} */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
