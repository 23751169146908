import axios from 'axios';
import React,{useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { IoMdSave } from "react-icons/io";
import apiUrl from '../apiConfig';
import { toast, ToastContainer } from "react-toastify";
import ForgotPasswordForm from "./ForgotPasswordForm";



export default function ChangePassword({url,isDarkMode, email}) {
    const [showpassword, setshowpassword] = useState("password");
    const [message,setMessage] = useState("");
    const {register,handleSubmit} = useForm();
    const token = localStorage.getItem("token")

    const validateForm = (password,cpassword)=>{
        if(password===cpassword){
            return true
        }
        else{
            return false
        }
    }

    const submitform = async (data) =>{
        if(validateForm(data.Password,data.CPassword)){
        try{
            const response = await axios.post(apiUrl.forgotpassword.changepassword+token,{
                currentpassword : data.CurrentPassword,
                newpassword : data.Password
            })
            if(response.status==200){
                setMessage("Password Changed Successfully")
                // toast.success('Password Changed Successfully');
                // // // console,log(response.data)
            }
        }
        catch(error){
            
            // toast.error('Enter correct current password');
            // // // console,log(error);
        }
    }
    }
    
  const showpass = () => {
    const type = showpassword === "password" ? "text" : "password";
    setshowpassword(type);
  };
  
  return (
    <div className={` ${
      isDarkMode ? "bg-[#282c34]" : "bg-white"
    } w-full  rounded-xl p-4`}>
        {/* <ForgotPasswordForm isDarkMode={isDarkMode} title='Change Password' email={email} url={url} /> */}
        <form onSubmit={handleSubmit(submitform)}>
            <h1 className='text-xl font-[550] font-sans'>Change Password</h1>
          <div className="mt-4">
            <label htmlFor="CurrentPassword" className="text-black font-[550]">
              Current Password
            </label>
            <input
              type={showpassword}
              className="p-2 border-[1.5px] rounded-md my-2 w-full"
              {...register("CurrentPassword", { required: true })}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="Password" className="text-black font-[550]">
              New Password
            </label>
            <input
              type={showpassword}
              className="p-2 border-[1.5px] rounded-md my-2 w-full"
              {...register("Password", { required: true })}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="CPassword" className="text-black font-[550]">
              Confirm Password
            </label>
            <input
              type={showpassword}
              className="p-2 border-[1.5px] rounded-md my-2 w-full"
              {...register("CPassword", { required: true })}
            />
          </div>
          {message && <p className="text-green-500">{message}</p>}
          <div className="mt-4">
            <button
              type="submit"
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
            >
             
              Save
            </button>
          </div>
        </form>
        <br />
       
    </div>
  )
}
