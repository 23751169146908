import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { logincontext } from "../contexts/Logincontext";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa"; // Import the spinner icon
import apiUrl from "../apiConfig";
import { IoMdInformationCircleOutline } from "react-icons/io";
export default function Loginform({ isDarkMode, url }) {
  const [
    currentuser,
    loginerror,
    UserloginStatus,
    Loginuser,
    Signupuser,
    Signupadmin,
    Logoutuser, setloginerror
  ] = useContext(logincontext);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false); // State to track loading
  
  const submitform = async (data) => {
    
    setLoading(true); // Set loading to true when form is submitted
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      Loginuser(data, url);
    } catch (error) {
      console.error("Login failed", error);
    } finally {
      setLoading(false); // Set loading back to false when operation completes
    }
  };

  useEffect(() => {
    setloginerror("")
    // // // console,log("login page visited")
  }, [])

  const [first, setfirst] = useState(true);

  const showpass = () => {
    setfirst(!first);
  };
  const handleForgotPassword = () => {
    navigate(`/forgotpassword`);
  };
  return (
    <div className="">
      <h1 className="text-center py-3 text-2xl font-[550] font-sans">Login</h1>
      {/* <p className="text-center">Please select one of the following</p>
      <br />
      <div className="flex flex-col justify-center items-center">
        <button className="btn border-[1.5px] border-slate-100 w-[80%] flex gap-3 items-center justify-center p-3 rounded-md">
          <FcGoogle size={20} />
          <p className="text-md font-[550]">Log in with Google</p>
        </button>
      </div>
      <br />
      <div className="flex flex-col justify-center items-center">
        <button className="btn border-[1.5px] border-slate-100 w-[80%] flex gap-3 items-center justify-center p-3 rounded-md">
          <GrGithub size={20} />
          <p className="text-md font-[550]">Log in with GitHub</p>
        </button>
      </div>
      <br />
      <div className="flex justify-between items-center px-6">
        <hr className="w-[45%] border-black" /> or{" "}
        <hr className="w-[45%] border-black" />
      </div> */}
      {loginerror && (
        <div className="text-red-700 flex justify-center items-center gap-1 ">
          <IoMdInformationCircleOutline />
          {loginerror}
        </div>
      )}
      <form onSubmit={handleSubmit(submitform)} className="p-4">
        <input
          type="email"
          placeholder="Email"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
            loginerror
              ? " border-red-700"
              : isDarkMode
              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
              : ""
          }`}
          name="Email"
          required
          {...register("email")}
        />
        <input
          type={first ? "password" : "text"}
          placeholder="Password"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
            loginerror
              ? " border-red-700"
              : isDarkMode
              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
              : ""
          }`}
          name="password"
          required
          {...register("password")}
        />
        <div className="flex md:justify-between py-4 flex-col md:flex-row gap-4">
          <div>
            <input
              type="checkbox"
              className=" cursor-pointer"
              name="show"
              id="show"
              onChange={showpass}
            />
            <label htmlFor="show" className=" cursor-pointer">
              {" "}
              Show password
            </label>
          </div>
          <p
            className=" text-[#0052ff] no-underline cursor-pointer"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </p>
        </div>
        <div className="mt-2 flex flex-row-reverse">
          {/* Render spinner if loading, otherwise render login button */}
          {loading ? (
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
          ) : (
            <button
              type="submit"
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]   font-[550] py-1 px-4 rounded-3xl"
            >
              Log in
            </button>
          )}
        </div>
      </form>
      <br />
      <hr />
      <br />
      <div className="text-md text-center">
        Don't have an account?{" "}
        <span>
          <NavLink
            to="/signup"
            onClick={() => {
              window.scroll(0, 0);
            }}
            className="text-[#0052ff] hover:text-[#014cec] font-[550] hover:underline"
          >
            {" "}
            Create!
          </NavLink>
        </span>
      </div>
      <br />
    </div>
  );
}
