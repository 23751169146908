import React, { useState, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import axios from "axios";
import apiUrl from "../apiConfig";
import { IoIosArrowUp } from "react-icons/io";
import DefaultCompany from "../images/DefaultCompany.png";
import VolExp from "./VolExp";

export default function VolExpcomponent({
  isDarkMode,
  dash,
  publicview,
  showvolexpmodal,
  setShowvolexpmodal,
  showAllVolExp,
  setShowAllVolExp,
  api,
  setapi,
  visibleItemsVol,
}) {
  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
  }, []);

  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;

    experience.forEach((item) => {
      const role = {
        Role: item.Role,
        fromMonth: item.fromMonth,
        fromYear: item.fromYear,
        toMonth: item.toMonth,
        toYear: item.toYear,
        description: item.description,
        _id: item._id,
      };
      if (lastCompany && lastCompany.Organisation === item.Organisation) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          Organisation: item.Organisation,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });

    return result;
  };

  const transformedExperience = transformExperienceData(dash.volexperience);
  // // // console,log(transformedExperience)
  const visiblejobitem = showAllVolExp
    ? transformedExperience
    : transformedExperience.slice(0, 2);

  return (
    <div
      className={`w-full  border-[1px] px-4 py-3 md:pt-5 md:px-6 md:rounded-md  ${
        isDarkMode ? "bg-[#3b4354]" : "bg-white"
      }`}
    >
      <div className="text-lg font-[550] flex justify-between ">
        Volunteering{" "}
        {!publicview && (
          <div className="flex gap-2">
            {" "}
            <div
              onClick={() => setShowvolexpmodal(!showvolexpmodal)}
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      {visiblejobitem.length !== 0 ? (
        visiblejobitem.map((item, key) => {
          // Find the corresponding university object
          const company = companies.find(
            (obj) => obj.Name === item.Organisation
          );
          // Get the image link if the university is found
          const image = company ? company.s3Link : DefaultCompany;
          return (
            <div key={key}>
              <VolExp
                props={{
                  token: token,
                  item: item,
                  image: image,
                  publicview: publicview,
                  isDarkMode: isDarkMode,
                  api: api,
                  setapi: setapi,
                  length: visiblejobitem.length,
                  key: key,
                }}
              />
            </div>
          );
        })
      ) : (
        <div className=" text-gray-500 text-md w-full text-center">
          {" "}
          No Experience mentioned{" "}
        </div>
      )}
      {transformedExperience.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center  border-slate-300">
          {/* <hr className="w-[30%]" /> */}
          <button
            className=" border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setShowAllVolExp(!showAllVolExp)}
          >
            {showAllVolExp
              ? "Show Less "
              : `Show All ${transformedExperience.length} `}{" "}
            <IoIosArrowUp
              className={showAllVolExp ? "rotate-0" : "rotate-180"}
            />
          </button>
          {/* <hr className="w-[30%]" /> */}
        </div>
      )}
    </div>
  );
}
