import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Months from "../asserts/Months";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../apiConfig";
import DefaultCompany from "../images/DefaultCompany.png";
export default function EditAawardsModal({
  handleshowcertmodal,
  values,
  isDarkMode,
  api,
  setapi,
}) {
  const [open, setopen] = useState(true);
  const { register, handleSubmit, formState: { errors} } = useForm();

  const [companies, setCompanies] = useState([]);
  const [selectedCompanyImage, setSelectedCompanyImage] = useState(DefaultCompany);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);
        const selectedObj =
          response.data.find((obj) => obj.Name == values.Issuer) ||
          response1.data.find((obj) => obj.Name == values.Issuer);
        setSelectedCompanyImage(selectedObj.s3Link);

        setCompanies(response.data.concat(response1.data));
        // setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
    setSelectedCompany(values.Issuer);
  }, []);

  const token = localStorage.getItem("token");
  const handlesubmit = async (data) => {
    // // // console,log(data);
    data.Issuer = selectedCompany;
    const reqbody = {
      awards: data,
      id: values._id,
    };

    try {
      const response = await axios.post(
        apiUrl.profile.editexp + token,
        reqbody
      );
      //// // // console,log(response);
      if (response.status == 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        setapi(!api);
        // // // console,log(response);
        handleshowcertmodal();
      }
    } catch (err) {
      //// // // console,log(err);
    }
  };

  const handleCompanySelect = (obj) => {
    setSelectedCompany(obj.Name);
    setSelectedCompanyImage(obj.s3Link);
    setFilteredCompanies([]);
  };

  const handleCompanyChange = (e) => {
    const inputSchoolOrCompany = e.target.value;
    setSelectedCompany(inputSchoolOrCompany);
    // Filter schools based on input
    // You would replace usSchools with the array containing your school names
    // For the purpose of this example, let's assume we have a dummy array called usSchools

    const filtered1 = companies.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchoolOrCompany.toLowerCase())
    );

    const filtered2 = companies.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchoolOrCompany.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchoolOrCompany == "") {
      setSelectedCompanyImage("");
    }

    setFilteredCompanies(finalFiltered);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCompanies.length > 0) {
        const dropdown = document.getElementById("schoolDropdown");
        if (dropdown && !dropdown.contains(event.target)) {
          setFilteredCompanies([]);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filteredCompanies]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleshowcertmodal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">Edit Award</div>

                    <IoMdClose
                      size={30}
                      onClick={handleshowcertmodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <br />
                  <div>
                    <form action="" onSubmit={handleSubmit(handlesubmit)} className="flex flex-col gap-4">
                      <div>
                        <label htmlFor="levelofedu" className=" font-[550]">
                          Organization
                        </label>
                        {/* <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  `}
                          name="Name"
                          defaultValue={values.Issuer}
                          {...register("Issuer")}
                        /> */}

                        <div
                          className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedCompanyImage && (
                            <img
                              src={selectedCompanyImage}
                              alt="Company Logo"
                              className="h-6 w-6 ml-2"
                            />
                          )}
                          <input
                            type="text"
                            className="focus:outline-none w-[99%]"
                            name="company"
                            {...register("Issuer", {
                              maxLength: {
                                value: 100,
                                message: "Organization must not exceed 100 characters",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                                message:
                                  "Organization can only contain alphanumeric characters, spaces, underscores, and hyphens",
                              },
                            })}
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                          />
                        </div>
                        {errors.Issuer && (
                          <p className="text-red-500 text-sm">
                            {errors.Issuer.message}
                          </p>
                        )}
                        {/* Dropdown menu for filtered schools */}
                        {filteredCompanies.length > 0 && (
                          <div
                            id="schoolDropdown"
                            className="z-10 mt-1 bg-white border rounded-md w-[100%] relative max-h-[150px] overflow-y-auto shadow-lg"
                          >
                            {filteredCompanies.map((obj, index) => (
                              <div
                                key={index}
                                className="cursor-pointer flex hover:bg-gray-100 p-2"
                                onClick={() => handleCompanySelect(obj)}
                              >
                                <img
                                  src={obj.s3Link}
                                  alt="Company Logo"
                                  className="h-6 w-6 mr-2 rounded-sm"
                                />
                                {/* Display School logo */}
                                {obj.Name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="levelofedu" className=" font-[550]">
                          Associated with
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  `}
                          name="Name"
                          defaultValue={values.Associatedwith}
                          {...register("Associatedwith", {
                            maxLength: {
                              value: 100,
                              message: "Associated with must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "Associated with can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                        />
                      {errors.Associatedwith && (
                          <p className="text-red-500 text-sm">
                            {errors.Associatedwith.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="levelofedu" className=" font-[550]">
                          Title <span className=" text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  `}
                          name="Name"
                          defaultValue={values.Title}
                          required
                          {...register("Title", {
                            maxLength: {
                              value: 100,
                              message: "Title must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "Title can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                        />
                      {errors.Title && (
                          <p className="text-red-500 text-sm">
                            {errors.Title.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="levelofedu" className=" font-[550]">
                          Link
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  `}
                          name="Name"
                          defaultValue={values.link}
                          {...register("link")}
                        />
                      </div>
                      <div>
                        <div className="pb-4">
                          <label
                            htmlFor="fromMonth"
                            className="font-[550] block"
                          >
                            Issue Date
                          </label>
                          <div className="flex justify-between">
                            <select
                              className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  `}
                              name="fromMonth"
                              defaultValue={values.issuemonth}
                              {...register("issuemonth")}
                            >
                              <option value="">Month</option>
                              {Months[0].map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                            <select
                              className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                isDarkMode
                                  ? "border-[#3b4354] bg-[#3b4354] "
                                  : ""
                              }`}
                              name="Month"
                              defaultValue={values.issueyear}
                              {...register("issueyear")}
                            >
                              <option value="">Year</option>
                              {Months[1].map((country, index) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="levelofedu" className=" font-[550]">
                          Description
                        </label>
                        <textarea
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="description"
                          rows="4"
                          defaultValue={values.Description}
                          {...register("Description")}
                        ></textarea>
                      </div>

                      <div className="flex flex-row-reverse w-full justify-between">
                        <button
                          type="submit"
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
