import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiUrl from "../apiConfig";
import axios from "axios";

export default function AddOrganization({
  addorg,
  setaddorg,
  isDarkMode,
  title,
}) {
  const [open, setopen] = useState(true);
  const closemodal = () => {
    setaddorg(!addorg);
  };
  const { handleSubmit, register, formState: {errors} } = useForm();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [error, setError] = useState("");
  const submit = async (data) => {
    const { name, website } = data;
    // console,log(data);
    const type = title;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("website", website);
    formData.append("logo", data.logo[0]);
    formData.append("type", type);
    // console,log(formData);
    try {
      const response = await axios.post(
        apiUrl.addorg.addorganization + token,
        formData
      );
      // console,log(response);
      closemodal();
    } catch (error) {
        setError(error.response.data.error);
      console.error(error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setaddorg(!addorg)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">Add {title}</div>

                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <br />
                  <div className="">
                    <form
                      action=""
                      onSubmit={handleSubmit(submit)}
                      className="flex flex-col gap-4 relative"
                    >
                      <div>
                         {error && (
                          <p className="text-red-400 text-sm text-center font-[550]">{error}</p>
                        )}
                        <label htmlFor="city" className="font-[550]">
                          {title}  <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="city"
                          {...register("name", {
                            maxLength: {
                              value: 100,
                              message: "name must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "name can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                        />
                      {errors.name && (
                          <p className="text-red-500 text-sm">
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="city" className="font-[550]">
                          Website <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="city"
                          {...register("website")}
                          required
                        />
                      </div>
                      <div className=" flex flex-col ">
                        <label className="font-[550]" htmlFor="logo">
                          Logo
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          name="logo"
                          // className="hidden"
                          className="file:text-[#0052ff] py-2 file:bg-white file:cursor-pointer focus:outline-none file:text-xs file:md:text-sm duration-300 cursor-pointer file:py-1 file:px-4 file:border file:rounded-3xl file:border-[#0052ff] file:hover:bg-blue-200 file:my-4 file:md:my-0"
                          {...register("logo")}
                        />
                       
                      </div>

                      <div className="flex flex-row-reverse w-full justify-between">
                        <button
                          type="submit"
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
