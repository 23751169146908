import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Label, Textarea } from "keep-react";
import apiUrl from "../apiConfig";
import { useNavigate } from "react-router-dom";
import axios from "axios";
export default function AccountDeletionFeedback() {
  const [decryptedEmail, setDecryptedEmail] = useState("");
  const [feedback,setFeedback] = useState("");
  const [searchParams] = useSearchParams(); // Hook to access URL query params
const navigate = useNavigate()
  useEffect(() => {
    const encryptedEmail = searchParams.get("email"); // Get the 'email' query param from the URL
    if (encryptedEmail) {
      setDecryptedEmail(decrypt(encryptedEmail, 7));
    }
  }, [searchParams]);

  function decrypt(encryptedString, shift) {
    let decryptedString = "";

    for (let i = 0; i < encryptedString.length; i++) {
      let charCode = encryptedString.charCodeAt(i);

      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        decryptedString += String.fromCharCode(
          ((((charCode - 65 - shift + 26) % 26) + 26) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        decryptedString += String.fromCharCode(
          ((((charCode - 97 - shift + 26) % 26) + 26) % 26) + 97
        );
      } else {
        decryptedString += encryptedString[i]; // Non-alphabetical characters
      }
    }

    return decryptedString;
  }

  const handleChangeFeedback = (e) =>{
    setFeedback(e.target.value);
  }

  const handleSubmitFeedback = async () =>{
    try{
        const response = await axios.post(apiUrl.sendeleteaccountconfirmationmessage, {
            email: decryptedEmail,
            feedback: feedback
        })
        if(response.status === 200){
            navigate("/");
        }
    }catch(error){
        // console,log(error);
    }
  }

  return (
    <div
      className={`flex justify-center w-full min-h-[90vh]  
    }`}
    >
      <div
        className={`max-w-screen-xl lg:w-[100%] w-full h-[100%] justify-between flex lg:flex-row flex-col gap-4 lg:p-4
     `}
      >
        <div className="w-full">
          <div className="text-2xl font-[550]">Feedback Form</div>
          <div className="flex flex-col gap-4 my-2 w-full">
            <fieldset className="space-y-1.5 py-2">
              <Label htmlFor="message">Your Feedback</Label>
              <Textarea
                id="message"
                placeholder="Write your feedback here"
                value={feedback}
                onChange={handleChangeFeedback}
                rows={8}
              />
            </fieldset>
            <button className="bg-blue-500 text-white p-2 my-2 rounded w-fit" onClick={handleSubmitFeedback}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
