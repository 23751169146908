import React from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiUrl from "../apiConfig";
import ComponentOrder from "./ComponentOrder";
import { useEffect } from "react";
export default function DocumentOrderModal({
  manageCompModal,
  isDarkMode,
  url,
  openCompModal
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [documentOrder, setDocumentOrder] = useState({});
  const [doc, setDoc] = useState([]);
  const token = localStorage.getItem("token");
  const [param, setParam] = useState(false);
  const [resume, setresume] = useState({});
  const [sectionschema, setsectionschema] = useState([]);
  useEffect(() => {
    const getDocuments = async () => {
      if (param == false) {
        try {
          const response = await axios.post(
            apiUrl.documents.getdocuments + `${token}`
          );
          if (response && response.data.documents) {
            // // // console,log(response.data.documents);
            setsectionschema(response.data.documents);
            const resp = {};
            response.data.documents.forEach((key) => {
              resp[key.Name] = key.Documents
            })
            setresume({
              Resume: resp["Resume"],
            });
            const newData = { ...resp };
            delete newData.Resume;
            setDocumentOrder(newData);
            setDoc(Object.keys(newData));
            // // // console,log();
            // // // // console,log(newArray);
            // setOpenDocumentModal(newArray);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    getDocuments();
  }, []);

  const documentsubmit = async () => {
    // // // // console,log(documentOrder);

    const reorderedComponentsObject = doc.reduce((acc, curr) => {
      acc[curr] = documentOrder[curr];
      return acc;
    }, {});

    // // // console,log(reorderedComponentsObject);


    const Sections = {
      Resume: resume.Resume,
      ...reorderedComponentsObject,
    };
    
    // // // console,log(Sections);

    const secs = []
    Object.keys(Sections).forEach(element => {
      let sample = {
        Name: element,
        Documents: Sections[element]
      }
      secs.push(sample);
    });

    // // // console,log(secs);
    // // // console,log(url);
    // // // console,log(reorderedComponentsObject);
    try {
      const response = await axios.put(
        apiUrl.documents.ordersection + token,
        secs
      );
      // // // console,log(response);
      if (response.status === 200) {
        // toast.success("order changed");
        manageCompModal();
      }
    } catch (err) {
      console.error(err);
      // Handle the error as needed
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={manageCompModal}>
             <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                      isDarkMode
                        ? "bg-[#2a303c] border-[#2a303c]"
                        : "bg-white text-black"
                    }`}
                  >
                   <div className="flex w-full items-end justify-between py-4 cursor-pointer">
               <div className="md:text-2xl text-lg font-[550]">
                        Section order
                  </div>
                    <IoMdClose size={30} onClick={manageCompModal} className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"/>
                  </div>

                  <ComponentOrder
                    url={url}
                    componentOrder={documentOrder}
                    setComponentOrder={setDocumentOrder}
                    comp={doc}
                    setComp={setDoc}
                    handleArrangeClick={documentsubmit}
                    isDarkMode={isDarkMode}
                    manageCompModal={manageCompModal}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
