import React, {useState} from "react";

import { Button, Modal } from "keep-react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RiDeleteBinLine } from "react-icons/ri";

export default function DeleteModal({ onClick, handleDeleteEducation, isDarkMode, inst, name }) {
    
  const [open, setOpen] = useState(true);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClick}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform   overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw]   w-[100%] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-8  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div
                   >
                   
                    <div className=" text-2xl capitalize font-[550] ">Delete {name}</div>
                    <div>
                      <div className="">
                        <p className="text-body-4 pt-4 leading-relaxed ">
                        Are you sure you want to delete your <span className="font-[550]">{inst}</span> {name}?
                        </p>
                      </div>
                    </div>
                    <br />
                    
                    <div className="flex w-full gap-4 justify-end items-center">
                      <div  className="  cursor-pointer font-[550] border-[1.5px] py-1 px-3 rounded-3xl my-4 md:my-0"
                          
                       onClick={onClick}>
                        Cancel
                      </div>
                      <div
                        type="primary"
                        className="bg-red-500 text-white   hover:bg-red-600  cursor-pointer font-[550] py-1 px-3 rounded-3xl my-4 md:my-0"
                          
                        onClick={handleDeleteEducation}
                      >
                        Confirm
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
