import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Connectionsdropdown from "./Connectionsdropdown";
import ProfileTile from "./ProfileTile";

export default function Sent({ sent, reject, findimage }) {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      {Object.keys(sent).length !== 0 ? (
        sent.map((user, index) => (
          <div
            key={index}
            className={` ${
              index === sent.length - 1 ? "" : "border-b-[1px]"
            } p-2 h-full flex md:flex-row flex-col justify-between md:items-start py-2 w-full`}
          >
            
            <ProfileTile user={user} findimage={findimage} />
            <div
              onClick={() => reject(user.url)}
              className="duration-300 mt-2 w-fit font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-gray-400 border-gray-400 hover:bg-[#80808025]"
            >
              Withdraw
            </div>
          </div>
        ))
      ) : (
        <div className=" text-base font-[550] text-center w-full  pb-2">
          No sent invitations
        </div>
      )}
    </div>
  );
}
