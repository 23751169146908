import React, { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuPencilLine } from "react-icons/lu";
import apiUrl from "../apiConfig";
import DeleteModal from "./DeleteModal";
import axios from "axios";
import EditAawardsModal from "./EditAwardsModal";
import { RxOpenInNewWindow } from "react-icons/rx";
import DashSecOptions from "./DashSecOptions";

export default function Awards({ props }) {
  const [showModal, setShowModal] = useState(false);
  const onClick = () => {
    // // // console,log(props);
    setShowModal(!showModal);
  };
  const handleDeleteAwards = async () => {
    try {
      const response = await axios.post(
        apiUrl.profile.deleteprofile + props.token,
        {
          awards: props.item,
        }
      );
      // // // console,log(response);
      if (response.status == 200) {
        // alert("Deleted Successfully");
        setShowModal(!showModal);
        // Reload the page after successful deletion
        // window.location.reload();
        props.setapi(!props.api);
      } else {
        // // // console,log(response.message);
      }
    } catch (error) {
      // // // console,log(error.response.body);
    }
  };

  const [showeditaward, setshoweditaward] = useState(false);
  const handleshowawardmodal = () => {
    setshoweditaward(!showeditaward);
  };

  return (
    <div className="pt-2">
      {showeditaward && (
        <EditAawardsModal
          handleshowcertmodal={handleshowawardmodal}
          values={props.item}
          isDarkMode={props.isDarkMode}
          api={props.api}
          setapi={props.setapi}
        />
      )}
      {showModal && (
        <DeleteModal
          onClick={onClick}
          handleDeleteEducation={handleDeleteAwards}
          isDarkMode={props.isDarkMode}
          inst={props.item.Title}
          name={"award"}
        />
      )}

      <div className=" flex justify-between w-[95%]">
        <div className="flex gap-2">
          {/* <div className="h-[50px]  aspect-square bg-slate-400"></div> */}
          <div className="md:h-[50px] h-[40px]  aspect-square py-[0.4rem]">
            {props.image && (
              <img src={props.image} alt="Company Logo" className="" />
            )}
          </div>

          <div className="text-md">
            <div className=" flex flex-col gap-0">
              <h1 className=" font-[550]">{props.item.Issuer}</h1>
              <p className="text-sm">{props.item.Title} </p>
              <p className="text-sm">{props.item.Associatedwith} </p>
              <div className="text-sm text-gray-400">
                {" "}
                {props.item.issuemonth} {props.item.issueyear}
              </div>
            </div>
            {/* <p className="">
          {" "}
          <span>skills:</span>
          a, b, c
        </p> */}
            <div className="py-2">
              {props.item.link && (
                <a
                  className=" border-gray-100 w-fit flex justify-center items-center gap-2 hover:bg-slate-100 hover:shadow-lg text-sm duration-300 cursor-pointer border-2 p-2 rounded-2xl"
                  target="_blank"
                  href={
                    props.item.link.startsWith("https://")
                      ? props.item.link
                      : "https://" + props.item.link
                  }
                >
                  <span>View award</span>
                  <RxOpenInNewWindow />
                </a>
              )}
            </div>
            <p className="text-sm text-justify w-full  ">
              {props.item.description}
            </p>
          </div>
        </div>
        {!props.publicview && (
          <div className="">
            <DashSecOptions
              edititem={handleshowawardmodal}
              deleteitem={onClick}
            />
          </div>
        )}
      </div>

      {props.length - 1 !== props.key && (
        <hr className=" text-center w-[95%] mt-2" />
      )}
    </div>
  );
}
