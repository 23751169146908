import React, { useState } from "react";
import { GoPlus } from "react-icons/go";
import EditSummaryModal from "../EditSummaryModal";
export default function SummaryComponent({
  isDarkMode,
  publicview,
  dash,
  api,
  setapi,
  manageSummarymodal,
  showsummarymodal,
}) {
  const [view, setview] = useState(true);
  return (
    <div className={`w-full px-4  pt-3 md:pt-5 md:px-6 border-[1px]  md:rounded-md bg-white`}>
      
      <div className="text-lg font-[550]  flex justify-between">
        Summary{" "}
        {!publicview && (
          <div className="flex gap-4 ">
            <div
              onClick={manageSummarymodal}
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      <div className=" text-sm  whitespace-pre-wrap">
        {view ? dash.summary.slice(0, 500) : dash.summary}{" "}
        {
          dash.summary.length >= 500 &&
        (view?<span
        className="text-blue-500 underline cursor-pointer"
        onClick={() => setview(false)}
        >
          ...more
        </span>: <span
        className="text-blue-500 underline cursor-pointer"
        onClick={() => setview(true)}
        >
          ...less
        </span>)
        }
      </div>
      <br />
    </div>
  );
}
