import React from "react";
import companies from "../asserts/companies.png";

export default function Landingsection3() {
  return (
    <div className=" text-center flex flex-col justify-center items-center ">
      <div className=" font-[550] md:text-4xl text-2xl ">
        AspireUp: Rise,Shine,and Align
      </div>
      <div className=" md:text-base text-sm">
        AspireUp is your essential gateway to unlocking endless opportunities,
        guiding you towards your brightest future.
      </div>

      <div className="md:w-[65%] w-full  flex  gap-4">
        <div className="w-[50%]  p-4">
          <img
            src={companies}
            alt=""
            className="hover:scale-[1.03] duration-150"
          />
        </div>
        <div className="w-[50%]  flex flex-col justify-evenly">
          <div className="flex items-center gap-2">
            <div>
              <svg
                className="w-[2rem]"
                viewBox="0 0 61 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1663 7.74878C7.34509 7.74878 5.08301 10.0109 5.08301 12.8321V38.2488C5.08301 39.597 5.61857 40.8899 6.57188 41.8432C7.52519 42.7965 8.81816 43.3321 10.1663 43.3321H30.4997V56.0404L38.1247 48.4154L45.7497 56.0404V43.3321H50.833C52.1812 43.3321 53.4742 42.7965 54.4275 41.8432C55.3808 40.8899 55.9163 39.597 55.9163 38.2488V12.8321C55.9163 11.4839 55.3808 10.191 54.4275 9.23765C53.4742 8.28434 52.1812 7.74878 50.833 7.74878H10.1663ZM30.4997 12.8321L38.1247 17.9154L45.7497 12.8321V21.7279L53.3747 25.5404L45.7497 29.3529V38.2488L38.1247 33.1654L30.4997 38.2488V29.3529L22.8747 25.5404L30.4997 21.7279V12.8321ZM10.1663 12.8321H22.8747V17.9154H10.1663V12.8321ZM10.1663 22.9988H17.7913V28.0821H10.1663V22.9988ZM10.1663 33.1654H22.8747V38.2488H10.1663V33.1654Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="">
              <div className=" font-[550] md:text-lg text-sm text-left">
                Online degrees
              </div>
              <div className=" text-xs text-left lg:block hidden">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugiat
                dolor vitae magnam adipisci rerum dignissimos. Ipsum
                reprehenderit laudantium rem voluptatibus iure quasi et officiis
                esse maiores ab, in corporis exercitationem.
              </div>
            </div>
          </div>
          <div className=" flex items-center gap-2">
            <div>
              <svg
                className="w-[2rem]"
                viewBox="0 0 55 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.25 25.3322C42.0292 25.3322 42.7854 25.4009 43.5417 25.5155V9.29049C43.5417 6.76965 41.4792 4.70715 38.9583 4.70715H11.4583C8.9375 4.70715 6.875 6.76965 6.875 9.29049V45.9571C6.875 48.478 8.9375 50.5405 11.4583 50.5405H28.0958C26.4172 48.1368 25.4299 45.3187 25.2414 42.3929C25.053 39.4671 25.6705 36.5457 27.0268 33.9464C28.3832 31.3472 30.4264 29.1697 32.9341 27.6509C35.4419 26.132 38.3182 25.33 41.25 25.3322ZM16.0417 25.3322V9.29049H27.5V25.3322L21.7708 21.8947L16.0417 25.3322Z"
                  fill="black"
                />
                <path
                  d="M41.2503 29.9154C34.9253 29.9154 29.792 35.0487 29.792 41.3737C29.792 47.6987 34.9253 52.8321 41.2503 52.8321C47.5753 52.8321 52.7087 47.6987 52.7087 41.3737C52.7087 35.0487 47.5753 29.9154 41.2503 29.9154ZM38.3857 47.1029V35.6446L47.5524 41.3737L38.3857 47.1029Z"
                  fill="black"
                />
              </svg>
            </div>
            <div>
              <div className=" font-[550] md:text-lg text-sm text-left">
                Short Courses
              </div>
              <div className=" text-xs text-left lg:block hidden">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Laboriosam inventore fugit, deserunt libero mollitia vero
                necessitatibus molestiae aliquid non quae enim id voluptatem
                labore asperiores illo cumque dolores perferendis?
                Reprehenderit!
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <svg
                className="w-[2rem]"
                viewBox="0 0 61 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clipRule="evenodd"
                  d="M40.6673 22.9987C40.6673 25.6951 39.5962 28.281 37.6896 30.1876C35.783 32.0942 33.197 33.1654 30.5007 33.1654C27.8043 33.1654 25.2184 32.0942 23.3117 30.1876C21.4051 28.281 20.334 25.6951 20.334 22.9987C20.334 20.3023 21.4051 17.7164 23.3117 15.8098C25.2184 13.9032 27.8043 12.832 30.5007 12.832C33.197 12.832 35.783 13.9032 37.6896 15.8098C39.5962 17.7164 40.6673 20.3023 40.6673 22.9987ZM35.584 22.9987C35.584 24.3469 35.0484 25.6398 34.0951 26.5932C33.1418 27.5465 31.8488 28.082 30.5007 28.082C29.1525 28.082 27.8595 27.5465 26.9062 26.5932C25.9529 25.6398 25.4173 24.3469 25.4173 22.9987C25.4173 21.6505 25.9529 20.3575 26.9062 19.4042C27.8595 18.4509 29.1525 17.9154 30.5007 17.9154C31.8488 17.9154 33.1418 18.4509 34.0951 19.4042C35.0484 20.3575 35.584 21.6505 35.584 22.9987Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clipRule="evenodd"
                  d="M30.5003 2.66541C15.0597 2.66541 2.54199 15.1831 2.54199 30.6237C2.54199 46.0644 15.0597 58.5821 30.5003 58.5821C45.9409 58.5821 58.4587 46.0644 58.4587 30.6237C58.4587 15.1831 45.9409 2.66541 30.5003 2.66541ZM7.62533 30.6237C7.62533 35.9358 9.43753 40.826 12.4748 44.7097C14.6079 41.9085 17.3597 39.6384 20.5152 38.0767C23.6708 36.515 27.1447 35.704 30.6655 35.7071C34.1408 35.7038 37.571 36.4938 40.6948 38.0169C43.8185 39.54 46.5534 41.756 48.691 44.4962C50.8932 41.6078 52.376 38.2366 53.0167 34.6614C53.6573 31.0862 53.4374 27.4099 52.3752 23.9366C51.3129 20.4633 49.4388 17.2928 46.908 14.6876C44.3772 12.0823 41.2624 10.1172 37.8213 8.95478C34.3802 7.79235 30.7118 7.46603 27.1196 8.00284C23.5273 8.53964 20.1145 9.92413 17.1636 12.0417C14.2127 14.1594 11.8084 16.9492 10.1498 20.1805C8.49114 23.4118 7.62578 26.9916 7.62533 30.6237ZM30.5003 53.4987C25.2491 53.5066 20.1564 51.7001 16.084 48.3849C17.7232 46.0383 19.905 44.1223 22.4437 42.8001C24.9825 41.4778 27.8031 40.7884 30.6655 40.7904C33.4923 40.7881 36.2788 41.4603 38.7936 42.7511C41.3084 44.0419 43.479 45.914 45.1251 48.2121C41.021 51.6349 35.8444 53.5062 30.5003 53.4987Z"
                  fill="black"
                />
              </svg>
            </div>
            <div>
              <div className="font-[550] md:text-lg text-sm text-left">
                Personalised Accounts
              </div>
              <div className="text-xs text-left lg:block hidden">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Corrupti at blanditiis odit velit dolorum eius quibusdam
                architecto magnam laboriosam ipsa, quasi, rem dolores quaerat
                nam aspernatur sapiente est, ab deleniti?
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
