import { Fragment, useEffect, useRef } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import "../../styles/carousel.css";
import logo from "../../asserts/logo.png";
import whitelogo from "../../asserts/whitelogopng.png";
import dummyDP from "../../asserts/dummyDp.png";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { PiDotsNineBold } from "react-icons/pi";
import { Badge } from "keep-react";
import { MdOutlineGroup } from "react-icons/md";
import favilogo from "../../asserts/favicon.png";

export default function RecruiterNavbar({
  isDarkMode,
  setIsDarkMode,
  toggleDarkMode,
  url,
  api,
  seturl,
  setapi,
}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const refreshtoken = localStorage.getItem("refreshtoken");
  const [fetchurl, setfetchurl] = useState("/recruiter");

  const handleLogout = () =>{
    localStorage.clear();
    navigate("/")
  }
  return (
    <div className="">
      <div
        className={` fixed  z-[5] w-full  flex justify-center border-b-[0.5px] border-[#eceff1] ${
          isDarkMode ? "bg-[#2a303c]" : "bg-white"
        }`}
      >
        <div className="max-w-screen-xl  min-w-[300px] w-[100%] ">
          <Disclosure as="nav">
            {({ open }) => (
              <>
                <div className="mx-auto px-4  w-full">
                  <div className="relative flex h-12 items-center justify-between w-full ">
                    <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start">
                      <div
                        className="flex flex-shrink-0 items-center"
                        onClick={token && fetchurl}
                      >
                        <NavLink to={token ? fetchurl : "/recruiter"}>
                          <img
                            className="h-8 w-auto md:inline-block hidden "
                            src={isDarkMode ? whitelogo : logo}
                            alt="Your Company"
                          />
                          <img
                            className="h-8 w-auto md:hidden inline-block "
                            src={isDarkMode ? whitelogo : favilogo}
                            alt="Your Company"
                          />
                        </NavLink>
                      </div>
                    </div>
                    <div>
                        <button className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]" onClick={handleLogout}>
                            Log out
                        </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
}
