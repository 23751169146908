import React from "react";
import HeroSection from "../components/HeroSection";
import { Accordioncomponent } from "../components/Accordioncomponent";
import { TiTick } from "react-icons/ti";
import { Accordion2 } from "../components/Accordion2";
import logos from "../asserts/complogos.png";
import { useNavigate } from "react-router-dom";

const sections = {
  "Top Instructors":
    "Learn from instructors of Smart Interviews, a leader in teaching coding in an intuitive approach",
  "Structured Course Curriculum":
    "Designed to equip you with all the skills required to ace technical rounds of top tech companies",
  "Success Coach":
    "A dedicated coach to help you stay on track throughout the program",
  "Career Skills Development":
    "Resume building, LinkedIn profile optimization, personal branding, and networking events",
};

const modules = {
  "Module 1": [
    "Basics of programming",
    "Data types & operators",
    "Complexity Analysis",
    "Bit-Manipulation & Applications",
    "Recursion / Backtracking",
  ],
  "Module 2": [
    "Sorting / Searching Techniques & Applications",
    "Hashing Implementation & Libraries",
    "Subarrays & Subsequences",
    "Strings & Rolling Hash",
    "Mixed-bag Concepts",
  ],
  "Module 3": [
    "Stacks & Queues",
    "Linked Lists",
    "LRU Cache",
    "Trees / Binary Trees / Binary Search Trees",
    "Priority Queues",
    "Trie DS & Applications",
  ],
  "Module 4": ["Dynamic Programming", "Graph Theory"],
};
function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b from-white to-[rgb(242,246,253)] p-4">
      <div className="max-w-screen-xl mx-auto flex flex-col gap-8">
        <div className="flex gap-8 flex-col lg:flex-row justify-center items-center py-8">
          <div className="flex flex-col lg:flex-row w-full md:w-[95%] lg:justify-between items-center">
            <div className="lg:w-[50%] flex flex-col justify-center gap-4 p-4">
              <div className="text-[1.5rem] lg:text-[2rem] font-[550] text-gray-800">
                Crack the toughest tech interviews
              </div>
              <div className="text-justify text-sm md:text-md text-gray-700">
                The technical interviews at top product companies are
                challenging. They test your problem-solving abilities through
                coding interviews to ensure that you can code to solve some of
                the hardest real-world problems. With our detailed guidance and
                your strong commitment, you can achieve your dream of working at
                a top product company. If you are committed to go through the
                journey of intensive learning, you are at the right place. 
                <span
                  onClick={() => {
                    navigate("/courses/dsa");
                    window.scrollTo(0, 0);
                  }}
                  className="text-blue-700 hover:text-blue-600 underline font-[550] cursor-pointer"
                >
                  Read on!
                </span>
              </div>
            </div>
            <div className="lg:w-[50%] p-4 flex justify-center items-center">
              <img src={logos} className="w-[80%]" alt="Logos" />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center w-full">
          <div className="w-full md:w-[95%] py-8">
            <div className="text-center font-[550] text-xl md:text-3xl py-4">
              Results Oriented Program
            </div>
            <div className="flex justify-center">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-4">
                {Object.keys(sections).map((item, key) => (
                  <div
                    key={key}
                    className="w-full h-full flex items-center justify-center min-h-[150px]"
                  >
                    <div className="shadow-lg rounded-md py-4 px-4 md:w-[400px] w-[90%] h-full bg-white transform hover:scale-105 transition-transform duration-300">
                      <div className="font-[550] py-1 text-md md:text-lg text-gray-800">
                        {item}
                      </div>
                      <div className="text-gray-700 text-sm md:text-md">
                        {sections[item]}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
