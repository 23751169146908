import React, { useEffect } from "react";
import { IoMdClose, IoMdInformationCircleOutline } from "react-icons/io";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import apiUrl from "../apiConfig";
import { useForm } from "react-hook-form";
import axios from "axios";
import { FaSpinner } from "react-icons/fa";

export default function WaitlistModal({
  vieweditprofile,
  setvieweditprofile,
  isDarkMode,
}) {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(true);
  const { register, handleSubmit } = useForm();
  const [error, seterror] = useState("");
  const [success, setsuccess] = useState("");
  const [closingtime, setclosingtime] = useState(3);
  const closemodal = () => {
    setvieweditprofile(!vieweditprofile);
  };
  const submitform = async (data) => {
    setloading(true);
    seterror("");
    setsuccess("");
    try {
    //   // console,log(data);
      const resp = await axios.post(apiUrl.waitlist.addwaitlist, data);
      // console,log(resp);
      if (resp && resp.status === 201) {
        setsuccess("You have been added to the waitlist");
        seterror("");
        let countdown = closingtime;
        const countdownInterval = setInterval(() => {
          setclosingtime(countdown);
          countdown--;
          if (countdown === -1) {
            clearInterval(countdownInterval);
          }
        }, 1000);

        setTimeout(() => {
          setvieweditprofile(!vieweditprofile);
        }, 5000);
      }
    } catch (error) {
      seterror(error.response.data.message);
      setsuccess("");
      // console,log(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setvieweditprofile(!vieweditprofile)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between cursor-pointer">
                    <div className="text-2xl font-[550]">Waitlist</div>

                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>

                  {success && (
                    <div>
                      <div className="text-green-700 flex justify-center items-center gap-1 pt-4 ">
                        {success} <br />{" "}
                      </div>
                      <div className="text-center text-gray-500">
                        You will be redirected in{" "}
                        <span className=" font-[550] text-gray-800">
                          {closingtime}
                        </span>{" "}
                        seconds
                      </div>
                    </div>
                  )}

                  {error && (
                    <div className="text-red-700 flex justify-center items-center gap-1 pt-4 ">
                      <IoMdInformationCircleOutline />
                      {error}
                    </div>
                  )}
                  <br />
                  <div className="">
                    <form
                      action=""
                      onSubmit={handleSubmit(submitform)}
                      className="flex flex-col gap-4 relative"
                    >
                      <div>
                        <label htmlFor="Email" className="font-[550]">
                          Email <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="Email"
                          required
                          {...register("email")}
                        />
                      </div>
                      <div>
                        <label htmlFor="firstname" className="font-[550]">
                          First Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="firstname"
                          required
                          {...register("firstname")}
                        />
                      </div>
                      <div>
                        <label htmlFor="lastname" className="font-[550]">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="lastname"
                          {...register("lastname")}
                        />
                      </div>
                      <div>
                        <label htmlFor="company" className="font-[550]">
                          Company
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="company"
                          {...register("company")}
                        />
                      </div>
                      <div>
                        <label htmlFor="school" className="font-[550]">
                          School
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="school"
                          {...register("school")}
                        />
                      </div>
                      <div className="flex flex-row-reverse w-full justify-between">
                        {loading ? (
                          <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
                        ) : (
                          <button
                            type="submit"
                            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                          >
                            Enroll
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
